import React, { Component } from "react";
import axios from "axios";

import Footer from "./Footer";
import Header from "./Header";
import Versioning from "./Versioning";
import ConfirmFailed from './../assets/confirmfailed.png';
import "./emailConfirm.scss";

export default class EmailHasConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    redirectingPage() {
        window.location.href = "https://dynlink.qoin.id/qoinpay_open"
    }

    render() {
        return (
            <div className="container">
                <Header />

                <div className="body-container">
                    <div className="content">
                        <img src={ConfirmFailed} className="img-content" />

                        <div className="text-header">
                            Email Kamu Sudah Pernah Diverifikasi
                        </div>
                        <div className="text-desc">
                            Email kamu sudah pernah diverifikasi. Silahkan kembali ke aplikasi dan ubah aset kripto kamu menjadi produk nyata yang kamu inginkan dengan Qoinpay.
                        </div>

                        <button className="button-back" onClick={() => this.redirectingPage()}>
                            Kembali Aplikasi
                        </button>
                    </div>
                </div>

                <Footer />
                <Versioning />
            </div>
        )
    }
}  