import React from 'react';
import ReactDOM from 'react-dom/client';
import CacheBuster from "react-cache-buster";
import version from "../package.json";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
