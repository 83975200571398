import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from 'axios';
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";
import Versioning from "./Versioning";
import "./emailConfirm.scss";

class Activating extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailKey: "",
            isSuccess: false,
            loading: true,
            invalidKey: true,
        };
    }

    validatingEmail = (key) => {
        let formdata = new FormData();
        formdata.append("EmailKey", key);
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/access/email/validation`,
                formdata,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                this.props.history.push('/verified')
            })
            .catch((err) => {
                this.props.history.push('/has-verified')
            });
    };

    componentDidMount() {

        let searchParams = this.props.location.search
        let splitKey = searchParams.split("?key=");
        if (!searchParams || splitKey.length != 2) {
            this.props.history.push('/404')
        }
        else {
            let key = splitKey[1]
            if (key) {
                this.setState({ emailKey: key });
                this.validatingEmail(key);
            }
            else {
                this.props.history.push('/404')
            }
        }

    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Qoinpay - Activation</title>
                </Helmet>

                <Header />

                <div className="body-container">
                    <div className="content">
                        <div className="sk-chase">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>

                        <div className="text-header">
                            Validating Key
                        </div>
                        <div className="text-desc">
                            {/* Akun kamu berhasil diverifikasi. Sekarang kamu dapat menggunakan fitur yang ada di Qoinpay. */}
                        </div>
                    </div>
                </div>

                <Footer />
                <Versioning />
            </div>
        )
    }
}

export default withRouter(Activating);