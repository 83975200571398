import React, { Component } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import Footer from "./Footer";
import Header from "./Header";
import Versioning from "./Versioning";
import NotFounds from './../assets/notfound.png';
import "./emailConfirm.scss";

export default class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Qoinpay - Not Found</title>
                </Helmet>

                <Header />

                <div className="body-container">
                    <div className="content">
                        <img src={NotFounds} className="img-content" />

                        <div className="text-header">
                            Oops! You`re Lost.
                        </div>
                        <div className="text-desc">
                            The page you are looking for was not found.
                        </div>

                        <button className="button-back">
                            Kembali Aplikasi
                        </button>
                    </div>
                </div>

                <Footer />
                <Versioning />
            </div>
        )
    }
}  