import React, { Component } from "react";

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="footer-container">
        <div className="text">
          Kamu punya pertanyaan, kritik, atau pun saran? Hubungi kami di:{" "}
          <a href="mailto:info@qoinpay.id" target="_blank">
            info@qoinpay.id
          </a>{" "}
          atau{" "}
          <a target="_blank" href="tel:+6281119190988">
            +62 811-1919-0988
          </a>
        </div>
        <div className="text">
          Ikuti kami di{" "}
          <a href="https://www.google.com/" target="_blank">
            Facebook,
          </a>
          <a href="https://www.google.com/" target="_blank">
            {" "}
            Instagram,
          </a>
          <a href="https://www.google.com/" target="_blank">
            {" "}
            Tiktok,
          </a>
          <a href="https://www.google.com/" target="_blank">
            {" "}
            Twitter,
          </a>
          <a href="https://www.google.com/" target="_blank">
            {" "}
            Youtube
          </a>
        </div>
      </div>
    );
  }
}
