import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import EmailConfirm from "./pages/EmailConfirm";
import EmailHasConfirm from "./pages/EmailHasConfirm";
import NotFound from "./pages/NotFound";
import Activating from "./pages/Activating";
import BackToApps from "./pages/BackToApps";
import PrivPol from "./pages/PrivPol";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<>Loading ..</>}>
        <Switch>
          <Route
            exact
            path="/verified"
            name="Email Response"
            render={(props) => <EmailConfirm {...props} />}
          />
          <Route
            exact
            path="/activate"
            name="Activate"
            render={(props) => <Activating {...props} />}
          />
          <Route
            exact
            path="/backtoapps"
            name="Redirecting"
            render={(props) => <BackToApps {...props} />}
          />
            <Route
            exact
            path="/kebijakan-ketentuan"
            name="kebijakan-ketentuan"
            render={(props) => <PrivPol {...props} />}
          />
          <Route
            exact
            path="/has-verified"
            name="Email Has Verfied"
            render={(props) => <EmailHasConfirm {...props} />}
          />
          <Route
            exact
            path="/404"
            name="404"
            render={(props) => <NotFound {...props} />}
          />
          <Route
            exact
            path="*"
            name="404"
            render={(props) => <NotFound {...props} />}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
