import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from 'axios';
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";
import Versioning from "./Versioning";
import "./emailConfirm.scss";

class BackToApps extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Qoinpay - Redirecting</title>
                </Helmet>

                <Header />

                <div className="body-container">
                    <div className="content">
                        <div className="sk-chase">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>

                        <div className="text-header">
                            Mohon Tunggu
                        </div>
                        <div className="text-desc">
                            {/* Akun kamu berhasil diverifikasi. Sekarang kamu dapat menggunakan fitur yang ada di Qoinpay. */}
                        </div>
                    </div>
                </div>

                <Footer />
                <Versioning />
            </div>
        )
    }
}

export default withRouter(BackToApps);