import React, { Component } from "react";

export default class Versioning extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="version-container">
                <div className="text">
                    Copyright © 2022 <a href="https://www.qoinpay.id/" target="_blank">Qoinpay</a>
                </div>
                <div className="text">
                    {process.env.REACT_APP_VERSION_WEB}
                </div>
            </div>
        )
    }
}