import React, { Component } from "react";
import { withRouter } from "react-router";
import "./landing.scss";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  onClickAggree() {
    this.props.history.push({
      // pathname: '/dresses',
      search: '?review=true'
    })
  }

  render() {
    return (
      <div className="container">
        <div className="header">Kebijakan Privasi & Ketentuan Layanan</div>
        <div className="warning">Mohon Dibaca Sampai Akhir!</div>
        <div className="landing" style={{ marginBottom: 50 }}>
          {/* kEBIJAKAN  */}
          <div>
            <div className="head-title">Kebijakan Privasi Qoinpay</div>
            <p>
              Kebijakan Privasi Akun Qoinpay Anda berlaku sejak 1 Agustus 2022.
            </p>
            <label>
              Kebijakan Privasi ini berlaku untuk layanan-layanan yang
              disediakan oleh PT Loyalty Program Indonesia (“LPI” atau
              “Qoinpay”) yang selalu memprioritaskan kenyamanan dan keamanan
              data Anda. Pada kebijakan privasi ini akan dijelaskan secara
              transparan bagaimana cara Qoinpay mengumpulkan, mendapatkan,
              menyimpan, mengolah, menampilkan, dan menggunakan, dan melindungi
              data pribadi Anda. Dengan menggunakan Qoinpay maka Anda mengakui
              bahwa Anda telah membaca, memahami dan menyetujui seluruh
              ketentuan yang terdapat pada kebijakan privasi, dan merupakan satu
              kesatuan dengan Ketentuan Layanan Qoinpay. Klik tautan berikut{" "}
              <a
                href="https://www.qoinpay.id/term-condition/"
                style={{
                  textDecoration: "none ",
                  fontWeight: "600",
                  color: "blue",
                }}
              >
                {"www.qoinpay.id/term-condition "}
              </a>
              untuk mengetahui Ketentuan Layanan Qoinpay.
            </label>
            <div className="content-landing">
              <h3>A. Data Pribadi</h3>
              <label>
                Aplikasi Qoinpay disediakan gratis bagi Anda yang sudah
                terdaftar. Siapa pun dapat mendaftar untuk memiliki akun pada
                aplikasi Qoinpay. Data yang bersifat pribadi dan sensitif yang
                termasuk tetapi tidak terbatas pada nama, alamat, tanggal lahir,
                tempat lahir, pekerjaan, nomor handphone, alamat email, daftar
                kontak, lokasi perangkat, mikrofon, kamera, gambar, nomor
                faksimili, rekening bank dan detail kartu kredit, informasi
                keuangan dan pembayaran, nomor identitas yang diterbitkan oleh
                instansi atau otoritas yang berwenang (termasuk KTP, SIM, dan
                Paspor) atau tanda pengenal lainnya yang dikeluarkan pemerintah,
                foto, kewarganegaraan, informasi biometric, serta informasi lain
                yang secara langsung maupun tidak langsung, baik secara terpisah
                atau bersama-sama dengan informasi lain, dapat digunakan untuk
                mengidentifikasi pengguna Qoinpay untuk kegiatan transaksi pada
                aplikasi Qoinpay.
              </label>
              <h3>B. Data Pribadi yang Qoinpay Kumpulkan</h3>
              <ol>
                <li className="my20px">
                  Data Pribadi yang dikumpulkan dari Anda atau perangkat seluler
                  Anda secara Langsung
                </li>
                <label>
                  Qoinpay akan mengumpulkan data pribadi dari Anda pada setiap
                  saat namun tidak terbatas pada saat membuat akun dan melakukan
                  transaksi di dalam aplikasi Qoinpay. Persetujuan atas
                  pengumpulan data pribadi Anda sesuai dengan Kebijakan Privasi
                  ini akan didapatkan dengan cara memilih untuk memberikan tanda
                  centang pada kotak atau indikasi positif lainnya yang
                  menunjukkan persetujuan dan/atau tidak adanya keberatan. Data
                  pribadi yang dikumpulkan di antaranya data sehubungan dengan:
                </label>
                <ul className="my20px">
                  <li className="my10px">
                    Informasi yang didapatkan ketika Anda mengakses aplikasi
                    Qoinpay, sehubungan dengan informasi sensitif, perangkat
                    telepon, bagian dari perangkat keras lain, perangkat lunak,
                    serta jaringan telekomunikasi yang digunakan oleh Anda untuk
                    mengakses dan menggunakan aplikasi Qoinpay (termasuk alamat
                    IP Anda, lokasi geografis, tipe dan versi browser/platform,
                    penyedia layanan internet, sistem operasi, sumber
                    rujukan,/laman keluar, lama kunjungan/penggunaan, tampilan
                    halaman dan istilah pencarian apapun yang Anda gunakan).
                  </li>
                  <li className="my10px">
                    Informasi yang didapatkan (secara langsung atau tidak
                    langsung) ketika Anda mendaftar/membuat akun Qoinpay
                    termasuk nama, nomor handphone dan email, tanggal lahir,
                    alamat, nomor telepon, foto, dan kartu identitas (termasuk
                    KTP, SIM, dan Paspor).
                  </li>
                  <li className="my10px">
                    Informasi yang didapatkan (secara langsung atau tidak
                    langsung) selama Anda menggunakan aplikasi Qoinpay, termasuk
                    nomor rekening bank Anda, informasi tagihan, pengiriman, dan
                    data transaksi (“Informasi Rekening”).
                  </li>
                  <li className="my10px">
                    Informasi yang didapatkan pada saat Anda melakukan
                    pengkinian informasi atau pada saat lainnya sebagaimana
                    dapat diminta oleh Qoinpay kepada Anda apabila dibutuhkan
                    dari waktu ke waktu.
                  </li>
                </ul>
                <li className="my10px">
                  Data Pribadi yang dikumpulkan dari Pihak Ketiga
                </li>
                <label>
                  Qoinpay dapat mengumpulkan Data Pribadi Anda dari pihak ketiga
                  (termasuk agen, vendor, pemasok, kontraktor, mitra, dan pihak
                  lainnya) yang memberikan layanan kepada Qoinpay atau
                  mitra-mitra lainnya yang bekerjasama dengan Qoinpay. Dalam hal
                  tersebut, pihak ketiga tersebut bertanggung jawab untuk
                  memperoleh persetujuan dari pihak-pihak yang berwenang yang
                  diperlukan dalam pengumpulan dan pengungkapan Data Pribadi
                  Anda kepada Qoinpay, dan Qoinpay akan melakukan pengolahan
                  Data Pribadi dengan bergantung pada persetujuan yang telah
                  diperoleh oleh pihak ketiga tersebut. Dalam hal ini, Qoinpay
                  hanya akan mengumpulkan informasi Data Pribadi Anda untuk atau
                  sehubungan dengan tujuan kerja sama Qoinpay dengan pihak
                  ketiga tersebut sesuai dengan kebutuhan.
                </label>
              </ol>
              <h3>C. Penggunaan Data Pribadi</h3>
              <label>
                Qoinpay dapat mengolah, menganalisis, dan/atau menggunakan data
                pribadi Anda untuk tujuan sebagai berikut maupun tujuan lain
                yang diizinkan oleh peraturan perundang-undangan yang berlaku
                (“Tujuan”), di antaranya:
              </label>
              <ol>
                <li className="my10px">
                  Melakukan verifikasi kelayakan, kesesuaian data, atau
                  verifikasi apapun untuk menggunakan fitur-fitur dan
                  fungsi-fungsi dalam aplikasi Qoinpay yang diperlukan sebelum
                  memberikan layanan kepada Anda atau sebelum Qoinpay
                  mendaftarkan Anda dan tidak terbatas pada proses Mengenal
                  Pelanggan (Know Your Customer).
                </li>
                <li className="my10px">
                  Mengidentifikasi, memproses, memelihara, dan mengelola data
                  pendaftaran akun Qoinpay Anda.
                </li>
                <li className="my10px">
                  Menyediakan layanan yang berkaitan dengan Andaan aplikasi
                  Qoinpay untuk mempermudah dalam identifikasi Anda aplikasi
                  Qoinpay lain saat melihat buku alamat (kontak) telepon selular
                  Anda melalui aplikasi.
                </li>
                <li className="my10px">
                  Meningkatkan dan mengembangkan layanan-layanan Qoinpay melalui
                  penelitian dengan pengembangan fungsi-fungsi baru atau produk
                  dan layanan-layanan baru lainnya yang mungkin akan ditawarkan
                  dari waktu ke waktu.
                </li>
                <li className="my10px">
                  Melakukan penelitian, analisis atau mengelola dan melindungi
                  bisnis Qoinpay termasuk infrastruktur teknologi informasi,
                  untuk mengukur kinerja aplikasi Qoinpay dan layanan-layanan
                  lain yang ditawarkan serta untuk memastikan kepuasan layanan
                  Qoinpay.
                </li>
                <li className="my10px">
                  Melakukan analisis trend, penggunaan dan perilaku-perilaku
                  lainnya (baik secara individual atau secara keseluruhan) yang
                  membantu untuk memahami bagaimana Anda mengakses serta
                  menggunakan aplikasi Qoinpay dan kegiatan komersial yang
                  dilakukan melalui aplikasi Qoinpay, termasuk untuk
                  meningkatkan layanan dan tanggapan terhadap pertanyaan serta
                  preferensi pelanggan.
                </li>
                <li className="my10px">
                  Mengacu kepada diperolehnya persetujuan dari Anda sesuai
                  dengan hukum yang berlaku maka Qoinpay dapat menggunakan data
                  pribadi untuk memberikan informasi pemasaran langsung kepada
                  Anda sehubungan dengan layanan yang Qoinpay tawarkan
                  menggunakan data pribadi Anda untuk menghubungi melalui
                  telepon, pesan singkat (SMS), dan surel (email).
                </li>
                <li className="my10px">
                  Mengelola risiko, melakukan pengecekan, menilai, mendeteksi,
                  menyelidiki, mencegah dan/atau memperbaiki penipuan atau
                  kegiatan-kegiatan yang berpotensi melanggar atau yang
                  melanggar peraturan perundang-undangan dan kepentingan
                  Qoinpay, ataupun untuk melindungi integritas platform
                  teknologi informasi Qoinpay.
                </li>
                <li className="my10px">
                  Mendeteksi, menyelidiki, mencegah atau memperbaiki
                  pelanggaran-pelanggaran atas Ketentuan Layanan, Kebijakan
                  Privasi, dan semua kebijakan internal Qoinpay yang berlaku
                  dari waktu ke waktu, undang-undang atau peraturan yang
                  terkait.
                </li>
                <li className="my10px">
                  Melakukan pengungkapan sebagaimana yang mungkin disyaratkan
                  oleh undang-undang atau peraturan dari suatu negara manapun
                  yang berlaku terhadap Qoinpay atau afiliasi Qoinpay, pejabat
                  pemerintah atau pihak ketiga. Pengungkapan juga dapat
                  dilakukan sehubungan dengan panggilan pengadilan, putusan
                  dan/atau perintah pengadilan, perintah, penegak hukum yang
                  berwenang dalam rangka penyidikan, penyelidikan, atau proses
                  hukum lainnya atau suatu persyaratan di suatu negara manapun
                  yang berlaku kepada Qoinpay atau afiliasi Qoinpay (termasuk
                  persyaratan pelaporan anti-pencucian uang dan anti-pendanaan
                  terorisme).
                </li>
                <li className="my10px">
                  Melakukan suatu pengungkapan untuk mencegah terjadinya suatu
                  bahaya atau kerugian keuangan untuk melaporkan setiap dugaan
                  kegiatan illegal atau untuk menghadapi setiap gugatan atau
                  potensi gugatan yang diajukan terhadap Qoinpay atau afiliasi
                  Qoinpay.
                </li>
                <li className="my10px">
                  Memberitahu Anda mengenai segala pembaruan pada aplikasi
                  Qoinpay atau perubahan pada layanan Qoinpay.
                </li>
                <li className="my10px">
                  Mengembangkan dan meningkatkan kualitas aplikasi untuk
                  memenuhi kebutuhan dan preferensi Anda Qoinpay terhadap
                  layanan-layanan yang dimiliki atau pengembangan kedepannya.
                </li>
                <li className="my10px">
                  Menawarkan atau menyediakan layanan dari mitra-mitra ataupun
                  afiliasi yang bekerja sama dengan Qoinpay kepada Anda.
                </li>
                <li className="my10px">
                  Mengirimkan informasi, iklan, promo, voucher, dan
                  informasi-informasi lainnya yang dinilai relevan oleh Qoinpay
                  kepada Anda.
                </li>
                <li className="my10px">
                  Memberikan bantuan teknis atau untuk menyelesaikan hambatan
                  teknis dan masalah operasional yang terjadi di aplikasi
                  Qoinpay.
                </li>
                <li className="my10px">
                  Untuk tujuan bisnis lain yang sah seperti melindungi Anda,
                  aplikasi Qoinpay dari kerugian, melindungi nyawa, menjaga
                  keamanan produk dan sistem Qoinpay dan melindungi kepentingan,
                  hak, dan/atau Qoinpay lainnya.
                </li>
                <li className="my10px">
                  Qoinpay juga dapat menggunakan data pribadi Anda dengan cara
                  lain, yang akan disertai dengan pemberitahuan khusus pada saat
                  pengumpulan data tersebut untuk disetujui oleh Anda.
                </li>
              </ol>
              <h3>D. Pengungkapan Data Pribadi</h3>
              <ol>
                <li className="my20px">
                  Data pribadi Anda yang tersimpan akan dijaga kerahasiaannya,
                  namun Qoinpay dapat memberikan dan membagikan data pribadi
                  Anda kepada pihak lain berikut untuk Tujuan sebagaimana diatur
                  dalam ketentuan Bagian C di atas:
                </li>
                <ul>
                  <li className="my10px">
                    Perusahaan anggota grup Qoinpay dan perusahaan yang memiliki
                    hubungan afiliasi dengan anggota grup Qoinpay dengan maksud
                    agar mereka membantu Qoinpay dalam menyediakan Aplikasi,
                    untuk mengoperasikan bisnis Qoinpay (termasuk, ketika anda
                    berlangganan milis Qoinpay, untuk tujuan pemasaran
                    langsung), atau untuk tujuan pengolahan data atas nama
                    Qoinpay. Misalnya, sebuah afiliasi Qoinpay di negara lain
                    dapat mengolah dan/atau menyimpan Informasi Pribadi anda
                    atas nama perusahaan grup Qoinpay di negara Anda. Semua
                    afiliasi Qoinpay berkomitmen untuk mengolah Informasi
                    Pribadi yang mereka terima dari Qoinpay sesuai dengan
                    Kebijakan Privasi ini dan peraturan perundang-undangan yang
                    berlaku ini.
                  </li>
                  <li className="my10px">
                    Pihak ketiga penyedia jasa (termasuk agen, vendor, pemasok,
                    kontraktor, mitra, dan pihak lain yang memberikan layanan
                    kepada Qoinpay atau anda, melakukan tugas atas nama Qoinpay,
                    atau pihak dengan siapa Qoinpay mengadakan kerja sama
                    komersial), untuk atau sehubungan dengan tujuan di mana
                    pihak ketiga tersebut terlibat, untuk melaksanakan
                    pengungkapan kepada pihak ketiga terkait yang secara teknis
                    diperlukan untuk memproses transaksi anda atau tujuan kerja
                    sama Qoinpay dengan pihak ketiga tersebut (tergantung
                    keadaannya), yang dapat mencakup diperbolehkannya pihak
                    ketiga tersebut untuk memperkenalkan atau menawarkan produk
                    atau layanan kepada anda, melakukan autentikasi terhadap
                    anda atau menghubungkan anda dengan melakukan kegiatan lain
                    termasuk pemasaran, penelitian, analisis dan pengembangan
                    produk
                  </li>
                </ul>
                <li className="my20px">
                  Ketika Data Pribadi tidak perlu dikaitkan dengan Anda, Qoinpay
                  akan melakukan upaya yang wajar untuk menghapus kaitan Data
                  Pribadi tersebut dengan Anda sebagai individu sebelum
                  mengungkapkan atau berbagi informasi tersebut.
                </li>
                <li className="my20px">
                  Qoinpay tidak akan menjual/menyewakan/menyebarkan data pribadi
                  Anda. Selain peraturan yang tertulis di atas Qoinpay dapat
                  mengungkapkan/membagikan data pribadi Anda untuk pihak lain,
                  setelah mendapat persetujuan Anda untuk melakukan pengungkapan
                  data pribadi.
                </li>
              </ol>
              <h3>E. Kegiatan Pemasaran Langsung</h3>
              <label>
                Seperti yang tertera pada bagian C di atas, Qoinpay dapat
                menggunakan data pribadi Anda untuk pemasaran langsung (Direct
                Marketing). Maka, Anda diminta untuk memberikan persetujuan
                terhadap segala aktivitas yang terkait dengan pemasaran yang
                akan dilakukan oleh Qoinpay sesuai dengan lingkup dan cara yang
                sesuai dan memenuhi semua persyaratan yang ditetapkan.
                Persetujuan atas data pribadi Anda yang dapat diberikan untuk
                tujuan pemasaran langsung akan didapatkan dengan cara memilih
                untuk memberikan tanda centang pada kotak atau indikasi positif
                lainnya yang menunjukkan persetujuan dan/atau tidak adanya
                keberatan.
              </label>
              <h3>F. Tindakan Keamanan dan Penyimpanan</h3>
              <ol>
                <li className="my10px">
                  Qoinpay mengambil semua yang wajar dan semaksimal mungkin
                  termasuk memberikan perlindungan teknis, administrasi dan
                  fisik untuk membantu melindungi data pribadi Anda yang Qoinpay
                  proses dari suatu resiko kehilangan, penyalahgunaan dan akses
                  tanpa izin, pengungkapan, perubahan dan penghancuran yang
                  tidak diinginkan.
                </li>
                <li className="my10px">
                  Qoinpay akan menyimpan dan memastikan penyedia layanan Qoinpay
                  untuk menyimpan data pribadi Anda hanya selama dibutuhkan
                  untuk tujuan-tujuan yang sudah ditetapkan dalam Kebijakan
                  Privasi ini dan sesuai dengan persyaratan dalam peraturan
                  perundang-undangan yang berlaku.
                </li>
                <li className="my10px">
                  Bagi Anda yang terdaftar pada Qoinpay maka informasi
                  pendaftaran dan informasi akun Anda (jika ada) dapat dilihat
                  dan diubah dalam aplikasi Qoinpay melalui akun Anda, yang
                  dilindungi oleh kata sandi, kode PIN, atau metode pengamanan
                  lainnya. Qoinpay mengingatkan kepada Anda untuk tidak
                  mengungkapkan kata kode PIN atau kunci pengamanan lain milik
                  Anda kepada pihak manapun. Karyawan, petugas, agen, dan
                  perwakilan Qoinpay tidak mengetahui dan tidak akan pernah
                  meminta kata sandi Anda dalam komunikasi melalui telepon yang
                  tidak diminta atau melalui media email yang tidak diminta.
                  Jika Anda menggunakan perangkat yang dapat digunakan oleh
                  orang lain (perangkat publik), maka Anda harus memilih untuk
                  tidak menyimpan informasi login Anda (misalnya: ID Anda dan
                  kata sandi) pada perangkat tersebut. Anda bertanggung jawab
                  untuk menjaga kerahasiaan detail akun anda, termasuk kata
                  sandi, kode PIN, dan One Time Password (OTP) dari siapapun dan
                  bertanggung jawab atas keamanan perangkat yang Anda gunakan.
                </li>
                <li className="my10px">
                  Qoinpay akan mematuhi seluruh peraturan dan hukum yang berlaku
                  dan menggunakan upaya terbaik Qoinpay untuk memastikan
                  Afiliasi dan Pihak Ketiga terkait untuk memberikan tingkat
                  perlindungan yang setara dengan komitmen Qoinpay dalam
                  Kebijakan Privasi ini.
                </li>
              </ol>
              <h3>G. Layanan Pihak Ketiga dan Situs Web</h3>
              <label>
                Aplikasi Qoinpay mungkin menyediakan tautan ke situs web dan
                layanan lain (termasuk aplikasi yang dioperasikan oleh pihak
                ketiga) untuk kemudahan dan informasi Anda. Layanan dan situs
                web ini dapat beroperasi secara independen dari Qoinpay dan
                mungkin memiliki pemberitahuan atau kebijakan privasinya
                sendiri, Qoinpay sarankan Anda untuk membaca terlebih dahulu
                sebelum menggunakan layanan mereka atau melakukan kegiatan apa
                pun di situs web tersebut. Penyediaan tautan ke situs web dan
                layanan lain sifatnya hanya merupakan informasi saja. Qoinpay
                tidak memberikan dukungan atau jaminan apapun terhadap konten
                dan informasi yang dimuat dalam tautan tersebut. Terhadap situs
                web yang Anda kunjungi yang tidak dimiliki atau dikendalikan
                oleh Qoinpay, Qoinpay tidak bertanggung jawab atas isi, praktik
                privasi, dan kualitas layanan tersebut.
              </label>
              <h3>H. Perubahan atas Kebijakan Privasi ini</h3>
              <label>
                Qoinpay memegang hak eksklusif untuk mengubah, menambah,
                dan/atau merevisi pemberitahuan dan informasi terkait Kebijakan
                Privasi ini dari waktu ke waktu, dalam hal mana Anda akan
                diminta untuk memberikan persetujuan terhadap Kebijakan Privasi
                yang telah diubah, ditambah, dan/atau direvisi. Apabila Anda
                tidak memberikan persetujuan terhadap Kebijakan Privasi yang
                telah diubah, ditambah, dan/atau direvisi tersebut, maka Qoinpay
                memiliki kewenangan penuh untuk menghentikan penyediaan
                layanan-layanan dalam aplikasi Qoinpay kepada Anda. Anda setuju
                bahwa anda bertanggung jawab untuk meninjau Kebijakan Privasi
                ini secara berkala untuk mendapatkan informasi terbaru mengenai
                pengolahan data dan praktik perlindungan data.
              </label>
              <h3>I. Berhenti Menerima Email</h3>
              <label>
                Qoinpay memiliki kebijakan untuk memilih masuk atau keluar dari
                basis data. Jika Anda ingin berhenti menerima email dari
                Qoinpay, dapat mengklik tautan berhenti berlangganan yang
                disertakan dalam setiap email. Namun demikian, dari waktu ke
                waktu Qoinpay dapat mengirimkan e-mail, SMS, atau komunikasi
                lainnya yang Qoinpay anggap perlu untuk tujuan penyediaan
                layanan, dan bukan merupakan promosi. Anda tidak dapat memilih
                untuk tidak menerima e-mail, SMS, atau komunikasi lain tersebut.
              </label>
              <h3>J. Data yang bersifat Pribadi dan Sensitif</h3>
              <ol>
                <li className="my10px">
                  Qoinpay dapat mengakses ke data Anda yang bersifat pribadi dan
                  sensitif (yang termasuk namun tidak terbatas pada daftar nomor
                  telepon, kontak, lokasi perangkat, mikrofon, kamera,
                  gambar,informasi keuangan dan pembayaran dan data lainnya yang
                  bersifat pribadi, selanjutnya disebut “Data Pribadi dan
                  Sensitif”) hanyadengan persetujuan Anda setelahAnda memilih
                  untuk memberikan tanda centang pada kotak atau indikasi
                  positif lainnya yang menunjukkan persetujuan dan/atau tidak
                  adanya keberatan.
                </li>
                <li className="my10px">
                  Data Pribadi dan Sensitif tersebut hanya dapat diakses
                  olehQoinpay dan hanyadigunakan untuk layanan terkait di dalam
                  Qoinpay yang telah Anda pilih.
                </li>
                <li className="my10px">
                  Penggunaan Data Pribadi dan Sensitif tersebut berdasarkan pada
                  Kebijakan Privasi ini dan ketentuan peraturan
                  perundang-undangan yang berlaku. Selain dari itu, Qoinpay
                  tidak akan menyimpan Data Pribadi dan Sensitif Anda ke dalam
                  database Qoinpay.
                </li>
              </ol>
              <h3>K. Data Anonim</h3>
              <label>
                Qoinpay dapat membuat, menggunakan, melisensikan, atau
                mengungkapkan Data Pribadi yang tersedia, dengan catatan (i)
                bahwa semua hal yang bersifat mengidentifikasi telah dihapus
                sehingga Data Pribadi, baik sendiri-sendiri atau yang telah
                dikombinasi dengan data lain yang tersedia tidak dapat
                dihubungkan dengan, dikaitkan dengan, atau tidak dapat
                mengidentifikasi suatu individu, dan (ii) Data Pribadi serupa
                telah digabungkan sehingga data asli membentuk bagian dari
                kumpulan data yang lebih besar.
              </label>
              <h3>L. Ketentuan Lain</h3>
              <ol>
                <li className="my10px">
                  Peraturan Data Pribadi ini tunduk, diatur, dan dilaksanakan
                  sesuai dengan hukum Republik Indonesia.
                </li>
                <li className="my10px">
                  Jika terjadi perselisihan dalam interpretasi dan implementasi
                  Peraturan Data Pribadi ini, Anda dan PT Loyalty Program
                  Indonesia setuju untuk menyelesaikannya dengan musyawarah.
                  Jika dalam jangka waktu 30 (tiga puluh) hari kalender Anda dan
                  PT Loyalty Program Indonesia tidak dapat mencapai suatu
                  kesepakatan melalui musyawarah tersebut, maka perselisihan
                  tersebut akan diselesaikan dengan merujuk pada Pengadilan
                  Negeri Jakarta Selatan.
                </li>
                <li className="my10px">
                  Peraturan Data Pribadi ini dibuat dalam . Dalam hal terjadi
                  inkonsistensi dan/atau pertentangan antara versi bahasa
                  Inggris dan versi bahasa Indonesia, maka versi Bahasa
                  Indonesia yang akan berlaku dan versi Bahasa Inggris akan
                  dianggap menyesuaikan dengan versi Bahasa Indonesia.
                </li>
                <li className="my10px">
                  Qoinpay akan menerapkan sistem pengamanan informasi yang
                  Qoinpay anggap memadai dan sesuai dengan standar industri atau
                  peraturan perundang-undangan. Namun, transmisi informasi
                  melalui saluran komunikasi tidak sepenuhnya aman dan bebas
                  dari celah. Dengan demikian, setiap transmisi informasi oleh
                  Anda kepada Qoinpay memiliki risiko keamanan, dalam hal mana
                  risiko tersebut ditanggung oleh Anda. Qoinpay tidak menjamin
                  keamanan database Qoinpay dan Qoinpay juga tidak menjamin
                  bahwa data yang Anda berikan sepenuhnya tidak akan
                  tertahan/terganggu saat sedang dikirim kepada Qoinpay.
                </li>
              </ol>
              <h3>M. Menjaga Keamanan Data Anda</h3>
              <label>
                Qoinpay berkomitmen untuk melindungi data pribadi anda sebagai
                Anda Qoinpay dimana Qoinpay berusaha mengimplementasikan langkah
                teknis sebaik mungkin, sesuai dengan standar industri atau
                peraturan perundang-undangan, untuk melindungi keamanan data
                pribadi anda. Namun, sebagai Anda, anda harus memahami bahwa
                tidak ada sistem yang sepenuhnya aman. Oleh karena itu, sebagai
                Anda, anda wajib menerapkan langkah-langkah untuk melindungi
                Akun Qoinpay Anda dengan menjaga penguasaaan nomor ponsel,
                Mnemonic/seed phrase, dan perangkat yang digunakan untuk
                mengakses aplikasi Qoinpay, serta merahasiakan kata sandi
                dan/atau kode PIN yang kuat dan unik untuk mencegah akses yang
                tidak diinginkan.
              </label>
              <h3>
                N. Penyimpanan, Akses, Koreksi, Pembaharuan, dan Penghapusan
                Data Anda
              </h3>
              <ol>
                <li className="my20px">
                  Qoinpay menyimpan data pribadi anda sebagai Anda selama
                  diperlukan untuk menyediakan layanan Qoinpay dan untuk tujuan
                  bisnis yang sah dan penting, diantaranya:
                  <ul>
                    <li className="my10px">
                      Dalam rangka mempertahankan kinerja dan kualitas layanan
                      pada fitur-fitur Qoinpay.
                    </li>
                    <li className="my10px">
                      Dalam rangka membuat keputusan bisnis berdasarkan data
                      yang anda berikan untuk pengembangan produk-produk dan
                      kualitas pelayanan Qoinpay
                    </li>
                    <li className="my10px">
                      Dalam rangka menyelesaikan masalah yang belum
                      terselesaikan terkait akun anda, seperti chargeback,
                      refund, ataupun sengketa lain, dimana Qoinpay akan
                      menyimpan data pribadi anda sampai masalah tersebut
                      terselesaikan
                    </li>
                    <li className="my10px">
                      Dalam rangka memenuhi kewajiban hukum, pajak, audit, dan
                      akuntansi Qoinpay, Qoinpay akan menyimpan data pribadi
                      yang diperlukan selama periode yang disyaratkan
                      berdasarkan hukum yang berlaku
                    </li>
                    <li className="my10px">
                      Atau untuk tujuan lain seperti yang telah disebutkan
                      diatas selama diperlukan untuk kepentingan bisnis Qoinpay
                      yang sah, seperti untuk mencegah penipuan atau menjaga
                      keamanan Anda sebagai pengguna Qoinpay.
                    </li>
                  </ul>
                </li>
                <li className="my20px">
                  Anda memiliki hak untuk meminta akses terhadap historis data
                  pribadi yang pernah diserahkan kepada Qoinpay. Dalam hal
                  demikian, Qoinpay akan menyediakan informasi tersebut dengan
                  format yang Qoinpay tentukan, dengan memperhatikan
                  kompatibilitas dan praktik yang wajar. Anda dapat menyampaikan
                  permintaan akses tersebut melalui Help Center yang tersedia di
                  aplikasi Qoinpay, atau informasi kontak yang disediakan pada
                  Bagian O.
                </li>
                <li className="my20px">
                  Anda memiliki hak melakukan koreksi atau memperbarui data
                  pribadi yang pernah diserahkan kepada Qoinpay. Anda dapat
                  melakukan koreksi atau pembaharuan tersebut melalui aplikasi
                  Qoinpay atau melalui Help Center yang tercantum di aplikasi
                  Qoinpay atau informasi kontak yang disediakan pada Bagian O.
                </li>
                <li className="my20px">
                  Anda memiliki hak untuk meminta penghapusan terhadap data
                  pribadi yang pernah diserahkan kepada Qoinpay yang sudah tidak
                  relevan, sesuai dengan ketentuan peraturan perundang-undangan.
                  Anda dapat menyampaikan permintaan penghapusan tersebut
                  melalui Help Center yang tercantum di aplikasi Qoinpay atau
                  informasi kontak yang disediakan pada bagian dibawah ini.
                </li>
              </ol>
              <h3>O. Pernyataan dan Jaminan</h3>
              <ol>
                <li className="my10px">
                  Dengan menyetujui Kebijakan Privasi, Anda mengakui bahwa Anda
                  telah membaca dan memahami Kebijakan Privasi ini dan
                  menyetujui segala ketentuannya. Secara khusus, Anda setuju dan
                  memberikan persetujuan kepada Qoinpay untuk mengumpulkan,
                  menggunakan, membagikan, mengungkapkan, menyimpan,
                  mentransfer, atau mengolah Data Pribadi Anda termasuk Data
                  yang bersifat Pribadi dan Sensitif, sesuai dengan Kebijakan
                  Privasi ini.
                </li>
                <li className="my10px">
                  Dalam keadaan dimana Anda memberikan kepada Qoinpay Data
                  Pribadi yang berkaitan dengan individu lain (seperti Informasi
                  Pribadi yang berkaitan dengan pasangan anda, anggota keluarga,
                  teman, atau pihak lain), Anda menyatakan dan menjamin bahwa
                  Anda telah memperoleh persetujuan dari individu tersebut
                  untuk, dan dengan ini menyetujui atas nama individu tersebut
                  untuk, pengumpulan, penggunaan, pengungkapan dan pengolahan
                  Informasi Pribadi mereka oleh Qoinpay.
                </li>
                <li className="my10px">
                  Anda dapat menarik persetujuan anda untuk setiap atau segala
                  pengumpulan, penggunaan atau pengungkapan Informasi Pribadi
                  Anda kapan saja dengan memberikan kepada Qoinpay pemberitahuan
                  yang wajar secara tertulis menggunakan rincian kontak yang
                  disebutkan di bawah ini. Tergantung pada keadaan dan sifat
                  persetujuan yang anda tarik, Anda harus memahami dan mengakui
                  bahwa setelah penarikan persetujuan tersebut, anda mungkin
                  tidak lagi dapat menggunakan aplikasi atau layanan Qoinpay.
                  Penarikan persetujuan Anda dapat mengakibatkan penghentian
                  Akun Anda atau hubungan kontraktual Anda dengan Qoinpay,
                  dengan semua hak dan kewajiban yang muncul sepenuhnya harus
                  dipenuhi. Setelah menerima pemberitahuan untuk menarik
                  persetujuan untuk pengumpulan, penggunaan atau pengungkapan
                  Informasi Pribadi Anda, Qoinpay akan menginformasikan anda
                  tentang konsekuensi yang mungkin terjadi dari penarikan
                  tersebut sehingga anda dapat memutuskan apakah anda tetap
                  ingin menarik persetujuan.
                </li>
              </ol>
              <h3>P. Informasi Lebih Lanjut</h3>
              <label>
                Apabila Anda hendak mengajukan pertanyaan atau menyampaikan
                keluhan, silahkan menghubungi Customer Service Qoinpay dibawah
                ini:
              </label>
              <p style={{ marginBottom: 5, fontWeight: "bold" }}>
                PT Loyalty Program Indonesia (LPI)
              </p>
              <label style={{ fontSize: "12pt" }}>
                Gedung Menara Citicon Lantai 11 Unit E <br />
                Jl. Letjen S Parman Kav.72, Slipi, Palmerah, Jakarta Barat, DKI
                <br />
                Jakarta 11480 Telepon :+62 855 9201 3099 Email : info@qoinpay.id
                <br />
              </label>
            </div>
          </div>
          {/* Syarat dan Ketentuan */}

          <div>
            <div className="head-title my20px">Ketentuan Layanan</div>
            <label>
              Ketentuan Layanan Pengguna Qoinpay berlaku sejak 1 Agustus 2022.
            </label>
            <p>
              Ketentuan Layanan yang ditetapkan dibawah ini mengatur pemakaian
              layanan yang ditawarkan oleh PT Loyalty Program Indonesia (“LPI”
              atau “Qoinpay”). Sebelum menggunakan layanan Qoinpay Pengguna
              diwajibkan untuk membaca keseluruhan Ketentuan Layanan Qoinpay.
              Jika memiliki pertanyaan Pengguna dapat menghubungi Layanan
              Pengguna (Customer Service) Qoinpay. Ketentuan Layanan ini
              mengatur Pengguna serta akses Pengguna terhadap aplikasi, website,
              konten, layanan-layanan yang disediakan oleh Qoinpay.
            </p>
            <p>
              Dengan menggunakan aplikasi Qoinpay maka Pengguna dinyatakan
              setuju untuk mematuhi “Ketentuan Layanan Pengguna Qoinpay” (atau
              disebut dengan “Ketentuan Layanan”) serta Kebijakan Privasi
              Qoinpay.
            </p>
            <div className="content-landing">
              <h3>A. Definisi</h3>
              <ol>
                <li className="my10px">
                  Aplikasi Qoinpay adalah platform digital dan layanan utilisasi
                  pembayaran Asset Crypto menjadi barang dan/atau jasa dan
                  terdapat berbagai macam Layanan yang tersedia pada fitur
                  platform digital “Qoinpay”.
                </li>
                <li className="my10px">
                  Akun Qoinpay adalah seluruh Pengguna yang terdaftar pada
                  aplikasi Qoinpay. Akun Qoinpay digunakan oleh Pengguna sebagai
                  akses masuk dan untuk memanfaatkan fasilitas dan Layanan dalam
                  Qoinpay.
                </li>
                <li className="my10px">
                  Qoinpay Wallet adalah Dompet Asset Crypto penyimpanan mandiri
                  (non-custodial), sehingga hanya Pengguna yang mempunyai akses
                  penuh ke Asset Crypto-nya.
                </li>
                <li className="my10px">
                  Layanan adalah layanan yang Qoinpay berikan atau sediakan
                  termasuk (a) situs, (b) layanan yang disediakan oleh Aplikasi
                  Qoinpay, dan (c) semua informasi, halaman tertaut, fitur,
                  data, teks, gambar, foto, grafik, musik, suara, video
                  (termasuk siaran langsung (jika ada)), pesan, tag, konten,
                  pemrograman, perangkat lunak, layanan aplikasi (termasuk,
                  namun tidak terbatas pada, setiap layanan aplikasi Qoinpay)
                  atau materi lainnya yang tersedia melalui Situs atau Aplikasi
                  Qoinpay. Setiap fitur baru yang ditambahkan atau memperbesar
                  layanan juga tunduk pada Syarat Layanan ini. Syarat Layanan
                  ini mengatur penggunaan Layanan yang disediakan oleh Qoinpay.
                </li>
                <li className="my10px">
                  Pengguna atau Pengguna Qoinpay adalah setiap orang yang
                  terdaftar sebagai pemilik akun Qoinpay serta pemilik saldo
                  Qoinpay Wallet sebagaimana tercatat dalam Qoinpay.
                </li>
                <li className="my10px">
                  Transaksi adalah seluruh transaksi yang dapat dilakukan oleh
                  Pengguna menggunakan Aplikasi Qoinpay, baik di dalam wilayah
                  Indonesia maupun di luar wilayah Indonesia (sebagaimana
                  relevan), baik yang telah tersedia melalui fitur-fitur pada
                  aplikasiQoinpay, maupun transaksi yang akan dikembangkan di
                  masa yang akan datang.
                </li>
                <li className="my10px">
                  Agen adalah pihak ketiga yang bekerja sama dengan Qoinpay
                  untuk melakukan penyediaan layanan-layanan atau fungsi-fungsi
                  tertentu agar dapat digunakan di aplikasi Qoinpay.
                </li>
                <li className="my10px">
                  Merchant adalah pihak-pihak baik badan hukum maupun badan
                  usaha ataupun perorangan yang menyediakan barang dan/atau jasa
                  bagi Pengguna melalui Transaksi menggunakan Qoinpay Wallet
                  berdasarkan perjanjian kerja sama dengan Qoinpay atau cara
                  kerjasama lainnya.
                </li>
                <li className="my10px">
                  QRIS YUKK adalah Quick Response Code Indonesia Standard yang
                  diselenggarakan oleh PT. YUKK Kreasi Indonesia sebagai salah
                  satu pihak ketiga yang berkerjasama dengan Qoinpay,
                </li>
                <li className="my10px">
                  YUKK Cash adalah salah satu fitur milik PT. YUKK Kreasi
                  Indonesia yang menampung uang Rupiah dalam bentuk non-tunai
                  yang tersedia dalam Qoinpay Wallet.
                </li>
              </ol>
              <h3>B. Ketentuan Umum</h3>
              <ol>
                <li className="my10px">
                  Pengguna baru dapat menggunakan akun Qoinpay setelah
                  menyetujui Ketentuan Layanan dan Kebijakan Privasi pada
                  Aplikasi Qoinpay, kemudian melakukan aktivasi atau pendaftaran
                  dengan menggunakan nomor ponsel serta memberikan informasi
                  yang dibutuhkan.
                </li>
                <li className="my10px">
                  Setiap nomor ponsel hanya dapat digunakan untuk 1 (satu) akun
                  Qoinpay.
                </li>
                <li className="my10px">
                  Transaksi dapat dilakukan penolakan apabila sewaktu-waktu
                  sistem keamanan Qoinpay menemukan dan menganggap bahwa
                  transaksi yang dilakukan tidak wajar.
                </li>
                <li className="my10px">
                  Dengan menggunakan Layanan Qoinpay atau membuka akun, Pengguna
                  memberikan penerimaan dan persetujuan yang tidak dapat dicabut
                  atas Ketentuan Layanan yang disebutkan di sini dan/atau
                  terkait di sini, Kebijakan Privasi Qoinpay, dan setiap
                  informasi yang tersedia di Aplikasi Qoinpay termasuk namun
                  tidak terbatas pada bagian Layanan Pengguna (Customer Service)
                  dan frequently asked questions dari Aplikasi Qoinpay.
                </li>
                <label className="my20px">
                  Apabila Pengguna tidak menyetujui ketentuan ini, mohon tidak
                  menggunakan Layanan Qoinpay. Apabila Pengguna di bawah usia 17
                  tahun atau berada dibawah pengampuan, Pengguna harus
                  mendapatkan izin dari orang tua atau wali untuk membuka akun
                  dan orang tua atau wali tersebut harus menyetujui Ketentuan
                  Layanan ini. Apabila Pengguna tidak memahami bagian ini, mohon
                  jangan membuat akun sampai Pengguna telah meminta bantuan
                  orang tua atau wali Pengguna. Apabila Pengguna adalah orang
                  tua atau wali anak di bawah umur yang membuat akun, Pengguna
                  harus menerima Ketentuan Layanan ini atas nama anak di bawah
                  umur tersebut dan Pengguna akan bertanggung jawab atas semua
                  penggunaan akun atau Layanan dari akun tersebut, baik apakah
                  akun tersebut sudah dibuka saat ini atau dibuat di masa
                  mendatang.
                </label>
              </ol>
              <h3>C. Aktivasi Qoinpay</h3>
              <label className="my20px">
                Pengguna wajib memastikan data, informasi dan/atau keterangan
                yang diberikan, dicantumkan, dan disampaikan pada aplikasi
                Qoinpay adalah benar dan valid. Pengguna dapat mendaftarkan akun
                Qoinpay dengan cara berikut:
              </label>
              <ol>
                <li className="my10px">
                  Mengunduh aplikasi Qoinpay melalui Google Play Store (untuk
                  Android) dan App Store (untuk IOS) dan melakukan instalasi
                  aplikasi Qoinpay.
                </li>
                <li className="my10px">
                  <label>
                    Setelah selesai melakukan instalasi, Pengguna dapat
                    melakukan proses registrasi dengan mengikuti petunjuk dan
                    memasukkan data antara lain:
                  </label>
                  <ul className="my20px">
                    <li className="my10px">Nomor ponsel aktif;</li>
                    <li className="my10px">
                      OTP (One Time Password) yang dikirimkan ke nomor ponsel
                    </li>
                    <li className="my10px">PIN (6 digit angka).</li>
                  </ul>
                </li>
                <li className="my10px">
                  Apabila Pengguna bermaksud meningkatkan status Akun Qoinpay
                  Pengguna menjadi Akun Qoinpay Premium, maka Pengguna akan
                  diminta dan bersedia untuk melakukan proses KYC (Know Your
                  Customer) menggunakan KTP atau Paspor.
                </li>
                <li className="my10px">
                  Setiap permintaan pendaftaran Akun Premium tersebut akan
                  dianggap sebagai permintaan yang sah dari Pengguna dan
                  Pengguna mengetahui dan menyetujui Qoinpay untuk meneruskan
                  permintaan tersebut kepada pihak ketiga penyedia YUKK Cash.
                </li>
                <li className="my10px">
                  Seluruh data atau informasi yang Pengguna kirimkan untuk
                  pendaftaran Akun Premium akan disimpan oleh Qoinpay dalam
                  rangka melakukan verifikasi terhadap permintaan Akun Premium
                  dari Pengguna, dan dapat diteruskan kepada pihak ketiga
                  penyedia YUKK Cash apabila diperlukan dengan tunduk pada
                  Kebijakan Privasi Qoinpay.
                </li>
                <li className="my10px">
                  Persetujuan dan/atau penolakan atas permohonan verifikasi
                  Pengguna merupakan kewenangan mutlak Qoinpay dengan
                  memperhatikan ketentuan hukum dan peraturan perundang-undangan
                  yang berlaku.
                </li>
                <li className="my10px">
                  Pengguna wajib menjamin kebenaran dan keakuratan seluruh data
                  dan informasi yang disampaikan Pengguna dalam rangka aktivasi
                  akun Qoinpay maupun verifikasi Akun Premium Qoinpay. Pengguna
                  bertanggung jawab secara penuh apabila di kemudian hari
                  ditemukan bahwa terdapat data dan informasi yang disampaikan
                  Pengguna yang tidak akurat, salah, dan/atau palsu.
                </li>
                <li className="my10px">
                  Dengan melakukan aktivasi akun Qoinpay dan/atau verifikasi
                  Akun Premium Qoinpay, Pengguna mengerti dan menyetujui untuk
                  memberikan akses kepada Qoinpay terhadap kontak nomor
                  handphone pada perangkat elektronik Pengguna untuk digunakan
                  sehubungan dengan aktivitas pada Aplikasi Qoinpay.
                </li>
              </ol>
              <h3>D. Otentikasi dan Verifikasi</h3>
              <label>
                Dalam hal otentikasi dan/atau verifikasi data, Qoinpay memiliki
                3 (tiga) metode yaitu melalui OTP (One Time Password),
                Mnemonic/Seed phrase dan PIN (Personal Identification Number).
              </label>
              <ol className="my20px">
                <li className="my10px">
                  OTP (One Time Password)
                  <ul className="my20px content-landing">
                    <li className="my10px">
                      OTP (One Time Password) adalah kode sandi yang dikirimkan
                      melalui WhatsApp dan panggilan telepon ke nomor telepon
                      Pengguna yang terdaftar di aplikasi Qoinpay. OTP berfungsi
                      untuk melakukan verifikasi atas transaksi atau aktivitas
                      tertentu yang dilakukan melalui aplikasi Qoinpay.
                    </li>
                    <li className="my10px">
                      Pengguna dapat meminta kode OTP sebanyak 3 (tiga)
                      kali/hari, dimana masing-masing pengiriman kode OTP
                      diberikan jeda 5 (lima) menit. Jika Pengguna sudah
                      mencapai limit maksimal permintaan OTP per hari, Pengguna
                      dapat meminta ulang OTP di hari berikutnya. Masa berlaku
                      OTP adalah 10 (sepuluh) menit dan hanya bisa digunakan 1
                      (satu) kali verifikasi saja. Jika Pengguna melakukan
                      permintaan kode OTP lebih dari satu kali maka OTP
                      sebelumnya akan dianggap kadaluarsa dan tidak dapat
                      digunakan.
                    </li>
                    <li className="my10px">
                      Pengguna Qoinpay wajib menjaga kerahasiaan kode OTP dan
                      bertanggung jawab sepenuhnya atas data diri, informasi,
                      maupun keamanan dan ketersediaan serta penguasaan
                      telekomunikasi serta nomor ponsel yang digunakan untuk
                      menerima kode OTP.
                    </li>
                    <li className="my10px">
                      Kerugian yang timbul akibat kegagalan/kelalaian Pengguna
                      dalam menjaga kerahasiaan dan keamanan OTP merupakan
                      tanggung jawab Pengguna sepenuhnya pihak Pengguna. Setiap
                      kode OTP yang berhasil terverifikasi dari akun Qoinpay
                      akan dianggap sebagai transaksi dan/atau aktivitas yang
                      sah dari Pengguna , dari dan oleh karenanya akan mengikat
                      dan memiliki akibat hukum terhadap Pengguna yang
                      bersangkutan.
                    </li>
                    <li className="my10px">
                      Pihak Qoinpay tidak mengetahui dan tidak akan pernah
                      meminta Pengguna memberitahukan kode OTP kepada Qoinpay.
                    </li>
                    <li className="my10px">
                      Biaya yang timbul atas pengiriman kode OTP ke nomor ponsel
                      Pengguna ditanggung oleh Pengguna.
                    </li>
                  </ul>
                </li>
                <li className="my10px">
                  Mnemonic/Seed Phrase
                  <ul className="content-landing my-20px">
                    <li className="my10px">
                      {" "}
                      Mnemonic/Seed Phrase adalah serangkaian kata sandi yang
                      digunakan untuk mengakses Qoinpay Wallet Asset Crypto.
                    </li>
                    <li className="my10px">
                      Mnemonic/Seed Phrase akan terdiri dari 12 (dua belas) kata
                      yang dipilih secara acak dari daftar 2.048 kata (dua ribu
                      empat puluh delapan) yang diterbitkan oleh suatu algoritme
                      ketika dompet dibuat Pengguna Qoinpay Wallet akan diminta
                      memasukkan Mnemonic/Seed Phrase ketika create atau import
                      wallet asset crypto
                    </li>
                    <li className="my10px">
                      Pengguna wajib menjaga kerahasiaan Mnemonic/Seed Phrase
                      dan tidak membagikan kepada siapapun dan/atau pihak lain
                      manapun.
                    </li>
                    <li className="my10px">
                      Kerugian yang timbul akibat kegagalan/kelalaian Pengguna
                      dalam menjaga kerahasiaan Mnemonic/Seed Phrase merupakan
                      tanggung jawab Pengguna sepenuhnya.
                    </li>
                    <li className="my10px">
                      Setiap pengiriman Mnemonic/Seed Phrase yang di verifikasi
                      dari akun Qoinpay milik Pengguna akan dianggap sebagai
                      transaksi dan/atau aktivitas yang sah dari Pengguna, dan
                      oleh karenanya akan mengikat dan memiliki akibat hukum
                      terhadap Pengguna yang bersangkutan.
                    </li>
                    <li className="my10px">
                      Dikarenakan Qoinpay Wallet merupakan dompet non-custodial
                      wallet, Pengguna Qoinpay Wallet wajib menyimpan dan
                      menjaga baik-baik secara sepenuhnya Mnemonic/Seed
                      Phrase-nya sendiri.
                    </li>
                    <li className="my10px">
                      Pihak Qoinpay tidak mengetahui dan tidak akan pernah
                      meminta Pengguna Qoinpay Wallet untuk memberitahukan
                      Mnemonic/Seed Phrase kepada Qoinpay.
                    </li>
                  </ul>
                </li>
                <li className="my10px">
                  PIN (Personal Identification Number)
                  <ul className="content-landing my20px">
                    <li className="my10px">
                      PIN (Personal Identification Number) adalah nomor
                      identifikasi pribadi yang dibuat dan ditentukan oleh
                      Pengguna pada saat proses registrasi akun Qoinpay baru.
                    </li>
                    <li className="my10px">
                      PIN terdiri dari 6 (enam) digit angka yang tidak boleh
                      berulang atau berurutan.
                    </li>
                    <li className="my10px">
                      Pengguna akan diminta memasukan PIN untuk kebutuhan
                      verifikasi tertentu dan/atau pemanfaatan fitur-fitur
                      tertentu untuk memastikan keabsahan bahwa transaksi
                      benar-benar dilakukan oleh Pengguna.
                    </li>
                    <li className="my10px">
                      Qoinpay berhak untuk melakukan blokir sementara atas akun
                      Qoinpay apabila Pengguna memasukkan PIN yang salah
                      sebanyak 3 (tiga) kali berturut – turut.
                    </li>
                    <li className="my10px">
                      Pengguna wajib menjaga kerahasiaan PIN dan bertanggung
                      jawab sepenuhnya atas kerahasiaan PIN dan tidak
                      membagikannya kepada siapa pun.
                    </li>
                    <li className="my10px">
                      Kerugian yang timbul akibat kegagalan/kelalaian Pengguna
                      dalam menjaga kerahasiaan kode PIN merupakan tanggung
                      jawab Pengguna sepenuhnya. Setiap pengiriman PIN yang di
                      verifikasi dari akun Qoinpay milik Pengguna akan dianggap
                      sebagai transaksi dan/atau aktivitas yang sah dari
                      Pengguna, dan oleh karenanya akan mengikat dan memiliki
                      akibat hukum terhadap Pengguna yang bersangkutan.
                    </li>
                    <li className="my10px">
                      Pihak Qoinpay tidak mengetahui dan tidak akan pernah
                      meminta Pengguna memberitahukan PIN kepada Qoinpay.
                    </li>
                    <li className="my10px">
                      Dalam hal akun Qoinpay Pengguna diblokir karena kesalahan
                      dalam memasukkan nomor PIN maka perlu dilakukan reset
                      ulang dengan menghubungi Customer Care Qoinpay melalui
                      WhatsApp atau email.
                    </li>
                  </ul>
                </li>
              </ol>
              <h3>E. Fitur-Fitur Aplikasi Qoinpay</h3>
              <label>
                Pengguna dapat melakukan berbagai transaksi melalui fitur-fitur
                yang tersedia pada aplikasi Qoinpay dengan memanfaatkan aset
                kripto yang dimiliki pada Qoinpay Wallet berdasarkan acuan nilai
                tukar secara real time dari pedagang aset kripto (exchanger)
                yang bekerja sama dengan Qoinpay serta mempertimbangkan tren
                dari nilai aset kripto yang dipilih oleh Pengguna.
              </label>
              <ol className="content-landing my20px">
                <li className="my10px">
                  QRIS (Quick Response Code Indonesian Standard)
                  <ul className="content-landing my20px">
                    <li className="my10px">
                      Saat ini Pengguna Qoinpay dapat melakukan transaksi
                      pembelian dan/atau pembayaran dengan menggunakan layanan
                      yang tersedia di Aplikasi Qoinpay termasuk salah satunya
                      adalah transaksi pembayaran digital dengan menggunakan
                      QRIS.
                    </li>
                    <li className="my10px">
                      Penggunaan pertama kali fitur scan QRIS harus didahului
                      dengan melakukan aktivasi pada penyelenggara QRIS Wallet
                      YUKK dengan memasukkan data pribadi yang diperlukan.
                    </li>
                    <li className="my10px">
                      Setelah melakukan aktivasi QRIS Wallet YUKK maka Pengguna
                      dapat secara langsung menggunakan fitur QRIS dan fitur
                      YUKK Cash akan otomatis muncul sebagai salah satu metode
                      transaksi pada Qoinpay Wallet Pengguna.
                    </li>
                    <li className="my10px">
                      Penyimpanan dan Penggunaan data pribadi tersebut akan
                      tunduk pada Kebijakan Privasi yang ada dalam Qoinpay.
                    </li>
                  </ul>
                </li>
                <li className="my10px">
                  PPOB
                  <p>
                    Pengguna Qoinpay dapat melakukan pembelian pulsa, paket
                    data, token listrik, serta pembayaran tagihan seperti pulsa
                    pascabayar, listrik pascabayar, BPJS Kesehatan, Gas, PDAM,
                    Internet & TV Kabel, dan lain sebagainya.
                  </p>
                </li>
                <li className="my10px">
                  Travel
                  <p>
                    Pengguna Qoinpay dapat melakukan reservasi hotel, tiket
                    pesawat, dan paket tour secara langsung melalui aplikasi
                    Qoinpay kemudian Pengguna akan mendapatkan e-voucher dari PT
                    Orbit Tanah Airku (OTAQU) sebagai pihak ketiga online travel
                    agent yang bekerja sama dengan Qoinpay.
                  </p>
                </li>
                <li className="my10px">
                  Belanja Online
                  <p>
                    Pengguna Qoinpay dapat melakukan pembelian produk secara
                    online seperti voucher, merchandise, dan lain sebagainya.
                  </p>
                </li>
                <li className="my10px">
                  Merchant
                  <p>
                    Pengguna Qoinpay dapat melakukan pembayaran online secara
                    langsung pada merchant yang tersedia di aplikasi Qoinpay.
                  </p>
                </li>
              </ol>
              <h3>F. Profil Pengguna</h3>
              <label>
                Pengguna dapat mengubah data pribadi Pengguna pada menu Profil.
                Adapun data yang dapat diubah yaitu Nama, Email, dan Foto
                Profil. PIN digunakan sebagai otentikasi untuk melakukan
                transaksi di aplikasi Qoinpay.
              </label>
              <h3>G. Qoinpay Wallet</h3>
              <ul>
                <li className="my10px">
                  Qoinpay Wallet memungkinkan Pengguna untuk mengirim, menerima,
                  menukar, dan menyimpan Asset Crypto, dengan memberikan
                  instruksi melalui Aplikasi “Qoinpay”.
                </li>
                <li className="my10px">
                  Qoinpay akan memproses transaksi sesuai dengan instruksi yang
                  diterima dari Pengguna. Pengguna harus memverifikasi semua
                  informasi transaksi sebelum mengirimkan instruksi kepada
                  Qoinpay. Qoinpay tidak menjamin identitas Pengguna, penerima,
                  pemohon, atau pihak lain. Transaksi tidak dapat dibatalkan
                  setelah disampaikan ke jaringan Asset Crypto yang relevan.
                </li>
                <li className="my10px">
                  Setelah diajukan ke jaringan Asset Crypto, Pengguna akan
                  menunggu konfirmasi dari Jaringan Asset Crypto untuk jangka
                  waktu tertentu.
                </li>
                <li className="my10px">
                  Dalam hal konfirmasi penggunaan Asset Crypto telah diberikan
                  namun transaksi penggunaan QRIS dan/atau penggunaan layanan
                  lainnya yang ada di Aplikasi Qoinpay dinyatakan tidak berhasil
                  termasuk namun tidak terbatas karena transaksi gagal,
                  dibatalkan dan/atau refund, maka Pengguna menyetujui bahwa
                  nilai yang akan dikembalikan kepada Pengguna adalah dalam
                  bentuk Rupiah dan akan tersedia dalam bentuk YUKK Cash pada
                  Qoinpay Wallet Pengguna.
                </li>
                <li className="my10px">
                  Nilai pengembalian (refund) adalah nilai tukar yang telah
                  dikonfirmasi setelah dipotong biaya layanan Qoinpay.
                </li>
                <li className="my10px">
                  Untuk dapat menggunakan saldo YUKK Cash yang terlihat di
                  Qoinpay Wallet, Pengguna harus mengunduh aplikasi YUKK dan
                  masuk dengan menggunakan nomor ponsel yang didaftarkan pada
                  saat aktivasi fitur scan QRIS di Aplikasi Qoinpay.
                </li>
                <li className="my10px">
                  Qoinpay berhak menolak untuk memproses atau membatalkan
                  transaksi yang tertunda seperti yang dipersyaratkan oleh hukum
                  atau pengadilan atau otoritas lain yang mana Qoinpay tunduk
                  pada yurisdiksi tersebut.
                </li>
                <li className="my10px">
                  Layanan Asset Crypto Qoinpay hanya tersedia sehubungan dengan
                  Asset Crypto yang didukung dan terdaftar pada Aplikasi
                  Qoinpay, dan hal ini dapat berubah dari waktu ke waktu. Dalam
                  situasi apapun Pengguna tidak boleh menggunakan Qoinpay Wallet
                  untuk menyimpan, mengirim, meminta, atau menerima Asset Crypto
                  dalam bentuk apa pun yang tidak Qoinpay dukung. Qoinpay tidak
                  bertanggung jawab atau berkewajiban sehubungan dengan upaya
                  apapun untuk menggunakan Qoinpay Wallet Pengguna untuk Asset
                  Crypto yang tidak didukung Qoinpay.
                </li>
              </ul>
              <h3>H. Kerjasama Pihak Ketiga dalam Aplikasi Qoinpay</h3>
              <label>
                Pengguna setuju dan menundukkan diri pada Ketentuan Layanan
                Aplikasi Qoinpay serta Ketenuan Layanan yang lebih khusus dalam
                produk atau fitur kerjasama pihak ketiga dengan PT Loyalty
                Program Indonesia yang dapat diakses dalam aplikasi baik yang
                tersedia saat ini maupun di masa mendatang dengan pemberitahuan
                sebelumnya, termasuk namun tidak terbatas pada bentuk kerjasama
                Merchant, partner (termasuk bank), aggregator, maupun jasa
                layanan pembayaran/keuangan lainnya.
              </label>
              <h3>I. Jangka Waktu</h3>
              <label>
                Akun Qoinpay milik Pengguna akan berlaku selama Pengguna tidak
                mengajukan penutupan akun secara permanen.
              </label>
              <h3>J. Biaya-Biaya Layanan Qoinpay</h3>
              <label>
                Biaya-biaya layanan Qoinpay terdiri dari gas fee dan service
                fee.
              </label>
              <ol className="my20px content-landing">
                <li className="my10px">
                  Biaya Gas (Gas Fee)
                  <p>
                    Biaya gas (gass fee) adalah biaya yang dibayarkan ke
                    blockchain miner untuk memproses transaksi blockchain. Gas
                    fee ditentukan berdasarkan tipe blockchain bukan dari
                    besarnya jumlah transaksi. Pengguna harus memastikan
                    memiliki saldo yang cukup di Qoinpay Wallet untuk
                    menyelesaikan transaksi beserta biaya gas yang dikenakan.
                  </p>
                </li>
                <li className="my10px">
                  Biaya Layanan (Service Fee)
                  <p>
                    Biaya layanan (service fee) diberlakukan untuk tujuan
                    pemelihatan sistem dan peningkatan layanan dalam
                    bertransaksi melalui Qoinpay. Qoinpay akan menghitung biaya
                    layanan sesuai kebijakannya dan akan secara otomatis
                    ditambahkan dalam perhitungan rate nilai tukar aset yang
                    dimiliki oleh Pengguna di dalam Qoinpay Wallet. Pajak-pajak
                    yang timbul atas Transaksi sesuai dengan ketentuan
                    perundang-undangan yang berlaku akan dimasukkan dalam
                    Service Fee.
                  </p>
                </li>
              </ol>
              <h3>K. Koreksi</h3>
              <ol>
                <li className="my10px">
                  Qoinpay dapat sewaktu-waktu melakukan koreksi (mendebet atau
                  mengkredit) apabila menurut catatan Qoinpay terjadi kesalahan
                  dalam transaksi, sistem, jaringan, maupun kesalahan dalam
                  bentuk lain, termasuk namun tidak terbatas apabila terdapat
                  permintaan dari Pengguna atau pejabat berwenang berdasarkan
                  peraturan yang berlaku.
                </li>
                <li className="my10px">
                  Terhadap permintaan koreksi oleh pihak ketiga, Qoinpay akan
                  melakukan tinjauan dan memutuskan berdasarkan penilaian dan
                  kebijakan Qoinpay. Qoinpay tidak akan melakukan koreksi tanpa
                  adanya informasi yang dianggap cukup bagi Qoinpay untuk
                  membuktikan adanya kesalahan dalam transaksi.
                </li>
                <li className="my10px">
                  Sehubungan dengan kepentingan koreksi sebagaimana disebutkan
                  dalam angka 1) dan angka 2), Pengguna dengan ini memberikan
                  kuasa kepada Qoinpay untuk mendebet dan/atau mengkredit
                  transaksi Pengguna. Kuasa sebagaimana dimaksud akan terus
                  berlaku dan tidak dapat berakhir selama Pengguna masih
                  terdaftar aktif sebagai Pengguna Qoinpay dan/atau masih
                  memiliki riwayat transaksi aktif di aplikasi Qoinpay.
                </li>
              </ol>
              <h3>L. Pemblokiran Atas Akun Qoinpay</h3>
              <ol>
                <li className="my10px">
                  Akun Qoinpay dapat diblokir seluruhnya atau terhadap sebagian
                  fitur, sesuai dengan kebijakan Qoinpay, dalam hal berikut:
                  <ul className="my20px content-landing">
                    <li className="my10px">
                      Pengguna mengajukan permintaan resmi karena adanya laporan
                      Pengguna bahwa ponsel Pengguna hilang/dicuri/diretas/rusak
                      dan atau nomor telepon yang terdaftar sudah tidak
                      digunakan lagi atau diblokir. Pengguna wajib menyertakan
                      informasi/dokumen pendukung sehubungan dengan permintaan
                      Pengguna.
                    </li>
                    <li className="my10px">
                      Dilakukan secara sepihak oleh Qoinpay dikarenakan terdapat
                      hal yang menurut kebijakan Qoinpay memiliki suatu indikasi
                      yang merugikan dan/atau indikasi pelanggaran serta
                      ketentuan hukum yang berlaku, dengan atau tanpa
                      pemberitahuan terlebih dahulu kepada Pengguna, termasuk
                      namun tidak terbatas pada:
                      <ul className="my20px content-landing">
                        <li className="my10px">
                          Adanya permintaan dari otoritas negara atau otoritas
                          penegak hukum yang berwenang, atau;
                        </li>
                        <li className="my10px">
                          Jika Pengguna menggunakan layanan Qoinpay yang
                          menyebabkan terjadinya pelanggaran terhadap Ketentuan
                          Layanan Pengguna dan/atau Kebijakan Privasi Qoinpay,
                          atau;
                        </li>
                        <li className="my10px">
                          Jika Pengguna memberikan data/informasi/keterangan
                          yang tidak benar atau menyesatkan pada saat melakukan
                          pendaftaran atau selama menggunakan aplikasi Qoinpay,
                          atau;
                        </li>
                        <li className="my10px">
                          Adanya indikasi penyalahgunaan Qoinpay untuk kegiatan
                          yang melanggar hukum dan peraturan perundang-undangan
                          yang berlaku, atau; – Akun Qoinpay tidak digunakan
                          untuk jangka waktu minimum selama 6 (enam) bulan
                          berturut-turut, atau;
                        </li>
                        <li className="my10px">
                          Berdasarkan pertimbangan Qoinpay yang cukup beralasan
                          memiliki alasan kuat lainnya.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="my10px">
                  Aktivasi ulang atas akun yang diblokir yang disebabkan
                  alasan-alasan sebagaimana dimaksud di atas merupakan wewenang
                  Qoinpay sepenuhnya untuk menonaktifkan sementara atau secara
                  permanen akses Pengguna terhadap sebagian atau seluruh
                  layanan/fitur aplikasi Qoinpay.
                </li>
              </ol>
              <h3>M. Informasi dan Jalur Pengaduan Resmi</h3>
              <label>
                Pengguna Qoinpay dapat mengajukan pertanyaan atau menyampaikan
                keluhan dengan menghubungi Layanan Pengguna (Customer Service)
                Qoinpay melalui:
              </label>
              <ol className="my20px content-landing">
                <li className="my10px">Email ke info@qoinpay.id </li>
                <li className="my10px">WhatsApp ke nomor +62 8111-919-0988 </li>
              </ol>
              <label>
                Pengguna juga dapat melihat informasi layanan Qoinpay serta
                promosi yang berlangsung di aplikasi Qoinpay atau website
                Qoinpay:{" "}
                <a
                  href="https://www.qoinpay.id/"
                  style={{
                    textDecoration: "none ",
                    fontWeight: "600",
                    color: "blue",
                  }}
                >
                  {" "}
                  www.qoinpay.id{" "}
                </a>
              </label>
              <h3>N. Batasan Tanggung Jawab</h3>
              <ol>
                <li className="my10px">
                  Untuk mencegah terjadinya penyalahgunaan data atau akun
                  Qoinpay maka Pengguna wajib menjaga kerahasiaan informasi data
                  yang dimilikinya, termasuk namun tidak terbatas pada Nomor
                  Ponsel (Handphone) yang terdaftar pada Qoinpay, PIN atau kode
                  verifikasi maupun Kode OTP, Mnemonic/Seed Phrase atau data
                  lainnya yang diberikan Pengguna atau diterima oleh Pengguna
                  pada setiap kegiatan Transaksi yang terjadi/dilakukan dengan
                  tidak mengungkapkan/menyebarkan kepada pihak manapun. Pengguna
                  bertanggung jawab sepenuhnya atas segala akibat dan risiko
                  yang timbul sehubungan dengan kelalaian Pengguna dalam menjaga
                  kerahasiaan informasi dan data Pengguna yang dimiliki.
                </li>
                <li className="my10px">
                  Pengguna wajib menjaga informasi sebagaimana disebut di atas,
                  dari peristiwa antara lain kehilangan, kerusakan,
                  penyalahgunaan oleh pihak yang tidak bertanggungjawab atau
                  pemalsuan. Pengguna dengan ini mengetahui dan menyetujui untuk
                  melepaskan Qoinpay dari tanggung jawab dan ganti kerugian
                  dalam bentuk apapun kepada Pengguna atau pihak manapun atas
                  hal-hal yang terjadi di luar kesalahan dan/atau kelalaian
                  Qoinpay termasuk tetapi tidak terbatas pada hal-hal:{" "}
                  <ul className="my20px content-landing">
                    <li className="my10px">
                      Kehilangan atau kerusakan perangkat telekomunikasi atau
                      nomor ponsel
                    </li>
                    <li className="my10px">
                      Akses tidak sah terhadap informasi pribadi Pengguna yang
                      terjadi di luar lingkup tanggung jawab Qoinpay atau
                      pada/melalui aplikasi milik pihak ketiga
                    </li>
                    <li className="my10px">
                      Setiap kerugian yang terjadi yang diakibatkan karena
                      Pengguna terindikasi melanggar hukum dan/atau terdapat
                      penyalahgunaan oleh pihak lain yang tidak berwenang.
                    </li>
                  </ul>
                </li>
                <li className="my10px">
                  Dalam hal terjadi kehilangan telepon genggam baik karena
                  pencurian, kehilangan atau alasan apapun maka Pengguna wajib
                  segera menghubungi Layanan Pengguna (Customer Service) Qoinpay
                  untuk melakukan pemblokiran akun Qoinpay.
                </li>
                <li className="my10px">
                  Sesuai dengan kebijakan Qoinpay berhak untuk menolak
                  permohonan pendaftaran Layanan Qoinpay oleh Pengguna tanpa
                  memberitahukan alasannya.
                </li>
                <li className="my10px">
                  Pengguna dengan ini diwajibkan untuk memeriksa, memastikan dan
                  menjamin bahwa seluruh informasi dan data yang didaftarkan
                  atau diberikan selama menggunakan/mengakses Qoinpay adalah
                  akurat, benar, dan lengkap, serta tidak menyesatkan. Oleh
                  karena itu Pengguna membebaskan dan melepaskan Qoinpay dari
                  segala bentuk gugatan, tuntutan dan/atau ganti kerugian baik
                  yang berasal dari Pengguna atau pihak manapun dan dalam bentuk
                  apapun sehubungan dengan kelalaian dan/atau kegagalan Pengguna
                  dalam mematuhi seluruh ketentuan dalam Ketentuan Layanan
                  Qoinpay.
                </li>
                <li className="my10px">
                  {" "}
                  Apabila transaksi telah diselesaikan maka Pengguna dengan ini
                  mengakui dan menyetujui bahwa Transaksi TIDAK DAPAT ditarik
                  kembali dengan alasan apapun juga dan Transaksi akan tetap
                  diproses sesuai dengan informasi dan data yang telah
                  didaftarkan dan dimasukkan oleh Pengguna.
                </li>
                <li className="my10px">
                  Pengguna akan dikenakan biaya-biaya sehubungan dengan Layanan
                  Qoinpay sesuai dengan peraturan yang berlaku termasuk namun
                  tidak terbatas pada biaya gas, biaya layanan, dan biaya-biaya
                  lainnya yang akan diinformasikan kepada Pengguna.
                </li>
                <li className="my10px">
                  Apabila terjadi gangguan teknis pada jaringan atau sedang
                  adanya peningkatan layanan atau jaringan, perubahan layanan
                  atau jaringan, perbaikan dan/atau pemeliharaan Layanan atau
                  jaringan yang digunakan oleh Qoinpay sehingga menyebabkan
                  gangguan pada Layanan Qoinpay, maka Qoinpay akan segera
                  menangani dan/atau memperbaikinya dalam jangka waktu maksimum
                  3 (tiga) hari kerja dan atas hal tersebut Pengguna akan
                  menerima pemberitahuan dari aplikasi Qoinpay. Jika waktu
                  perbaikan/penanganan gangguan teknis memakan waktu lebih lama
                  maka Qoinpay akan menyampaikan pemberitahuan lebih lanjut.
                </li>
                <li className="my10px">
                  Dalam hal terjadi kesalahan sistem Aplikasi Qoinpay karena
                  alasan apapun yang mengakibatkan terganggunya Layanan atau
                  kesalahan dalam pelaksanaan Layanan atau Transaksi yang bukan
                  disebabkan oleh Pengguna, maka Qoinpay akan memperbaiki
                  kesalahan tersebut dengan sesegera mungkin atau dalam waktu 1
                  (satu) hari kerja atau maksimal 3 (tiga) hari kerja sejak
                  ditemukan kesalahan tersebut.
                </li>
              </ol>
              <h3>O. Keadaan Memaksa (Force Majeure)</h3>
              <label>
                Tidak dilaksanakannya atau tertundanya pelaksanaan sebagian atau
                keseluruhan kewajiban berdasarkan Ketentuan Layanan Qoinpay
                tidak akan dianggap sebagai pelanggaran terhadap Ketentuan
                Layanan apabila hal tersebut disebabkan oleh suatu peristiwa
                yang berada di luar kendali Qoinpay atau lazim disebut dengan
                istilah Keadaan Memaksa (Force Majeure) termasuk namun tidak
                terbatas pada:
              </label>
              <ol className="my20px content-landing">
                <li className="my10px">Bencana alam</li>
                <li className="my10px">
                  Kebakaran, pemogokan buruh, perang, huru-hara, pemberontakan
                  atau tindakan militer lainnya
                </li>
                <li className="my10px">
                  Tindakan pihak/instansi yang berwenang yang mempengaruhi
                  kelangsungan penyediaan layanan telekomunikasi
                </li>
                <li className="my10px">
                  Tindakan pihak ketiga yang menyebabkan Qoinpay tidak dapat
                  memberikan layanan telekomunikasi
                </li>
                <li className="my10px">
                  Adanya keputusan dari instansi yang berwenang atau perubahan
                  keputusan dari pemerintah yang berdampak pada layanan Qoinpay
                  termasuk diantaranya perubahaan pemberlakuan tarif kepada
                  Pengguna; dan
                </li>
                <li className="my10px">
                  Wabah, epidemi, dan/atau pandemi penyakit. Pengguna setuju
                  untuk melepaskan Qoinpay dari setiap klaim, jika Qoinpay tidak
                  dapat memenuhi instruksi Pengguna baik sebagian maupun
                  seluruhnya karena adanya Keadaan Memaksa.
                </li>
              </ol>
              <h3>P. Keamanan dan Kerahasiaan Transaksi</h3>
              <ol>
                <li className="my10px">
                  Qoinpay mengambil semua langkah yang wajar dan semaksimal
                  mungkin termasuk memberikan perlindungan teknis, administrasi
                  dan fisik untuk membantu melindungi data pribadi Pengguna yang
                  Qoinpay proses dari suatu resiko kehilangan, penyalahgunaan
                  dan akses tanpa izin, pengungkapan, perubahan dan penghancuran
                  yang tidak diinginkan
                </li>
                <li className="my10px">
                  Qoinpay akan menyimpan dan memastikan penyedia layanan Qoinpay
                  untuk menyimpan data pribadi Pengguna hanya selama dibutuhkan
                  untuk tujuan-tujuan yang sudah ditetapkan sebagaimana yang
                  tertulis dalam Kebijakan Privasi dan sesuai dengan persyaratan
                  dalam peraturan perundang-undangan yang berlaku
                </li>
                <li className="my10px">
                  Bagi Pengguna yang terdaftar pada Qoinpay maka informasi
                  pendaftaran dan informasi akun Pengguna (jika ada) dapat
                  dilihat dan diubah dalam aplikasi Qoinpay melalui akun
                  Pengguna, yang dilindungi oleh kata sandi, kode PIN, atau
                  metode pengamanan lainnya. Qoinpay mengingatkan kembali kepada
                  Pengguna untuk tidak mengungkapkan kata sandi atau kode PIN
                  atau kunci pengamanan lain milik Pengguna kepada pihak
                  manapun. Karyawan, petugas, agen, dan perwakilan Qoinpay tidak
                  mengetahui dan tidak akan pernah meminta kata sandi Pengguna
                  dalam komunikasi melalui telepon yang tidak diminta atau
                  melalui media email yang tidak diminta. Jika Pengguna
                  menggunakan perangkat yang dapat digunakan oleh orang lain
                  (perangkat publik), maka Pengguna harus memilih untuk tidak
                  menyimpan informasi login Pengguna (misalnya: ID Pengguna dan
                  kata sandi) pada perangkat tersebut. Pengguna bertanggung
                  jawab untuk menjaga kerahasiaan detail akun Pengguna, termasuk
                  kata sandi, kode PIN, dan One Time Password (OTP) dari
                  siapapun dan bertanggung jawab atas keamanan perangkat yang
                  Pengguna gunakan.
                </li>
                <li className="my10px">
                  Qoinpay akan mematuhi seluruh peraturan dan hukum yang berlaku
                  dan menggunakan upaya terbaik Qoinpay untuk memastikan
                  Afiliasi dan Pihak Ketiga terkait untuk memberikan tingkat
                  perlindungan yang setara dengan komitmen Qoinpay dalam
                  Kebijakan Privasi ini.
                </li>
              </ol>
              <h3>Q. Pernyataan dan Jaminan</h3>
              <ol>
                <li className="my10px">
                  Pengguna menyatakan dan menjamin bahwa berdasarkan hukum dan
                  peraturan perundang-undangan yang berlaku Pengguna merupakan
                  pihak yang cakap dan mengerti untuk menggunakan layanan yang
                  disediakan oleh Qoinpay.
                </li>
                <li className="my10px">
                  Pengguna dengan ini menyatakan dan menjamin bahwa telah sah
                  secara hukum untuk terikat dalam perjanjian berdasarkan hukum
                  Republik Indonesia, secara khusus terikat dalam Ketentuan
                  Layanan. Pengguna pada saat mengakses, melakukan pendaftaran,
                  dan/atau menggunakan Qoinpay, telah berusia sekurang-kurangnya
                  21 tahun atau kurang dari 21 tahun, mempunyai kartu identitas
                  (KTP) dan tidak sedang berada dalam pengampuan. Apabila
                  Pengguna berusia kurang dari 21 tahun atau kurang dari 21
                  tahun dan belum memiliki kartu identitas (KTP) maka Pengguna
                  menyatakan dan menjamin bahwa telah mendapat persetujuan dari
                  orang tua/wali yang sah melakukan pendaftaran, dan/atau
                  menggunakan Qoinpay.
                </li>
                <li className="my10px">
                  Penggunaan atas Akun dan/atau layanan Qoinpay merupakan
                  pengakuan dan persetujuan pengguna untuk tunduk pada:
                  <ul className="my20px content-landing">
                    <li className="my10px">
                      Ketentuan Layanan Aplikasi Qoinpay
                    </li>
                    <li className="my10px">
                      Ketentuan Layanan khusus Qoinpay yang bekerja sama dengan
                      penyedia aplikasi pihak ketiga
                    </li>
                    <li className="my10px">
                      Ketentuan Layanan khusus terkait promo
                    </li>
                    <li className="my10px">
                      Ketentuan Layanan khusus yang berlaku untuk masing-masing
                      fitur layanan yang tersedia dalam aplikasi Qoinpay
                    </li>
                    <li className="my10px">Kebijakan Privasi</li>
                    <li className="my10px">
                      Setiap ketentuan hukum dan peraturan perundang-undangan
                      yang berlaku.
                    </li>
                  </ul>
                </li>
                <li className="my10px">
                  Pengguna menyatakan dan menjamin akan menggunakan Qoinpay
                  secara wajar dan benar sesuai dengan Ketentuan Layanan serta
                  peraturan perundang-undangan yang berlaku di Republik
                  Indonesia, dan tidak melakukan tindakan apapun yang bertujuan
                  untuk menghindari, menembus, dan/atau memanfaatkan celah
                  sistem keamanan ataupun menyebabkan gangguan dan/atau
                  kerusakan pada sistem, melakukan penyalahgunaan akun, produk
                  dan fitur-fitur dalam aplikasi, maupun menyebabkan kerugian
                  bagi Qoinpay dan/atau Pengguna lain. Pengguna bertanggung
                  jawab penuh atas segala konsekuensi terhadap pelanggaran
                  ketentuan ini.
                </li>
                <li className="my10px">
                  Pengguna menyatakan dan menjamin bahwa dana yang dipergunakan
                  dalam transaksi bukanlah dana yang berasal dari tindak pidana
                  yang terlarang berdasarkan peraturan perundang-undangan yang
                  berlaku di Republik Indonesia. Pembukaan akun tidak untuk
                  tujuan melakukan tindak pidana pencucian uang dan pendanaan
                  terorisme sesuai dengan ketentuan peraturan perundang-undangan
                  yang berlaku di Republik Indonesia, transaksi tidak dilakukan
                  untuk maksud mengelabui, mengaburkan, atau menghindari
                  pelaporan kepada Pusat Pelaporan dan Analisis Transaksi
                  Keuangan (PPATK) berdasarkan ketentuan peraturan
                  perundang-undangan yang berlaku di Republik Indonesia,
                  transaksi tidak dilakukan untuk melakukan pembayaran atas
                  transaksi yang sifatnya atau objeknya melanggar peraturan
                  perundang-undangan yang berlaku di Republik Indonesia, dan
                  Pengguna bertanggung jawab sepenuhnya serta melepaskan Qoinpay
                  dari segala tuntutan, klaim, atau ganti rugi dalam bentuk
                  apapun apabila Pengguna ternyata terbukti melakukan tindak
                  pidana pencucian uang berdasarkan ketentuan peraturan
                  perundang-undangan yang berlaku di Republik Indonesia.
                </li>
                <li className="my10px">
                  Pengguna dengan ini menyatakan dan menjamin akan bertanggung
                  jawab sepenuhnya atas semua layanan Qoinpay yang diakses
                  menggunakan nomor ID Pengguna miliknya.
                </li>
                <li className="my10px">
                  Pengguna dengan ini menyatakan bersedia melengkapi seluruh
                  data atau permintaan data atau dokumen pendukung yang diminta
                  oleh Qoinpay sehubungan dengan adanya penelusuran/pemeriksaan
                  lanjutan jika terdapat dugaan transaksi mencurigakan dan/atau
                  terindikasi melanggar Ketentuan Layanan atau Ketentuan
                  peraturan perundang-undangan.
                </li>
                <li className="my10px">
                  Pengguna tidak akan memberikan hak, wewenang dan/atau kuasa
                  dalam bentuk apapun dan dalam kondisi apapun kepada orang atau
                  pihak lain untuk menggunakan namun tidak terbatas pada data,
                  PIN, dan/atau Mnemonic/Seed Phrase Qoinpay kepada orang atau
                  pihak manapun;
                </li>
                <li className="my10px">
                  Pengguna menyatakan dan menjamin tidak akan dengan alasan
                  apapun meminta kembali setiap dan seluruh data/dokumen yang
                  telah disampaikan karena Qoinpay berhak menggunakan data dan
                  informasi tersebut untuk layanan transaksi atau terkait urusan
                  administrasi aset Pengguna pada Qoinpay, kecuali diwajibkan
                  berdasarkan peraturan perundang-undangan
                </li>
                <li className="my10px">
                  Pengguna menyatakan dan menjamin akan memperbaharui dan
                  memberitahukan kepada Qoinpay apabila terdapat perubahan data
                  Pengguna yang telah didaftarkan dan/atau disampaikan kepada
                  Qoinpay
                </li>
                <li className="my10px">
                  Dengan melaksanakan Transaksi melalui aplikasi Qoinpay maka
                  Pengguna memahami bahwa seluruh komunikasi dan instruksi dari
                  Pengguna yang diterima oleh Qoinpay akan diperlakukan sebagai
                  bukti otentik meskipun tidak dibuat dalam bentuk dokumen
                  tertulis atau diterbitkan dalam bentuk dokumen yang
                  ditandatangani. Pengguna dengan ini menyatakan dan menjamin
                  bahwa Pengguna tidak dan tidak akan menyangkal keberlakuan dan
                  keabsahan dari komunikasi dan instruksi elektronik yang
                  disampaikan oleh Pengguna melalui Qoinpay, sebagai bukti
                  otentik
                </li>
                <li className="my10px">
                  Pengguna dengan ini menyatakan memahami dan setuju bahwa
                  layanan yang disediakan “sebagaimana adanya” dan “sebagaimana
                  tersedia”, tanpa adanya jaminan bahwa layanan yang disediakan
                  sesuai untuk tujuan atau kebutuhan tertentu dari pengguna.
                  Dalam hal ini, Qoinpay tidak memberikan jaminan bahwa:
                  <ul className="my20px content-landing">
                    <li className="my10px">
                      Penggunaan Qoinpay dapat memenuhi seluruh kebutuhan
                      Pelanggan
                    </li>
                    <li className="my10px">
                      Qoinpay akan tersedia terus menerus tanpa gangguan/error
                    </li>
                    <li className="my10px">
                      Setiap ketidaksesuaian akan diperbaiki untuk memenuhi
                      keinginan pengguna. Namun Qoinpay tetap akan berusaha
                      sebaik mungkin dan sewajarnya untuk menyediakan layanan
                      yang terbaik bagi Pengguna.
                    </li>
                  </ul>
                </li>
              </ol>
              <h3>R. Pendaftaran dan Keamanan Dompet</h3>
              <ol>
                <li className="my10px">
                  Pengguna akan mendapatkan Mnemonic/Seed Phrase yang terdiri
                  dari rangkaian 12 kata ketika membuat dompet Qoinpay.
                </li>
                <li className="my10px">
                  Saat Pengguna membuat dompet Qoinpay, Pengguna sangat
                  disarankan untuk mengambil tindakan pencegahan untuk
                  menghindari hilangnya akses ke dan/atau kendali atas dompet
                  Pengguna.
                </li>
                <li className="my10px">
                  Tindakan yang disarankan termasuk, namun tidak terbatas pada,
                  hal berikut:
                  <ul className="my20px content-landing">
                    <li className="my10px">
                      Membuat kata sandi unik yang kuat yang tidak Pengguna
                      gunakan untuk tujuan lain (yaitu berbeda dengan kata sandi
                      ponsel atau kata sandi lain yang digunakan untuk situs
                      web, layanan online, dll)
                    </li>
                    <li className="my10px">
                      Memanfaatkan otentikasi biometrik jika tersedia.
                    </li>
                    <li className="my10px">
                      Jangan menyimpan Mnemonic/Seed Phrase dalam teks biasa
                      secara online atau di lokasi fisik yang tidak aman.
                    </li>
                    <li className="my10px">
                      Membatasi akses ke perangkat dan dompet.
                    </li>
                    <li className="my10px">
                      Mengambil semua tindakan pencegahan yang diperlukan
                      terhadap malware di perangkat dan jaringan.
                    </li>
                    <li className="my10px">
                      Segera memberi tahu Qoinpay jika Pengguna menemukan atau
                      mencurigai adanya pelanggaran keamanan yang terkait dengan
                      dompet Pengguna.
                    </li>
                    <li className="my10px">
                      Terlepas dari apa pun yang bertentangan di sini, Qoinpay
                      tidak akan memiliki tanggung jawab apa pun sehubungan
                      dengan aktivitas yang terjadi di dompet Pengguna dengan
                      atau tanpa izin Pengguna.
                    </li>
                  </ul>
                </li>
                <li className="my10px">
                  Pengguna mengakui dan setuju bahwa Pengguna akan menanggung
                  semua risiko yang terkait dengan penggunaan Layanan dan
                  Pengguna bertanggung jawab penuh untuk menjaga kerahasiaan dan
                  keamanan Mnemonic/Seed phrase.
                </li>
                <li className="my10px">
                  Kegagalan backup Mnemonic/Seed phrase dapat mengakibatkan
                  hilangnya kendali Aset Digital yang terkait dengan dompet
                  Pengguna.
                </li>
                <li className="my10px">
                  Jika Pengguna belum menyimpan backup Mnemonic/Seed phrase
                  dengan aman, Pengguna menerima dan mengakui bahwa Aset Digital
                  apa pun yang Pengguna kaitkan dengan alamat dompet tersebut
                  akan menjadi tidak dapat diakses. Oleh karena itu, Qoinpay
                  tidak akan memiliki tanggung jawab atau kewajiban apa pun jika
                  Pengguna tidak dapat mengakses dompet Pengguna karena alasan
                  apa pun termasuk namun tidak terbatas pada kegagalan Pengguna
                  untuk menjaga keamanan Mnemonic/Seed phrase dompet Qoinpay.
                </li>
                <li className="my10px">
                  Qoinpay tidak dapat membuat kata sandi baru untuk dompet
                  Pengguna jika gagal mengingat kata sandi asli.
                </li>
                <li className="my10px">
                  Kerugian yang timbul akibat kegagalan/kelalaian Pengguna dalam
                  menjaga kerahasiaan Mnemonic/Seed phrase merupakan tanggung
                  jawab Pengguna sepenuhnya. Setiap pengiriman Mnemonic/Seed
                  phrase yang di verifikasi dari akun Qoinpay milik Pengguna
                  akan dianggap sebagai transaksi dan/atau aktivitas yang sah
                  dari Pengguna, dan oleh karenanya akan mengikat dan memiliki
                  akibat hukum terhadap Pengguna yang bersangkutan.
                </li>
                <li className="my10px">
                  Pihak Qoinpay tidak mengetahui dan tidak akan pernah meminta
                  Pengguna memberitahukan Mnemonic/Seed phrase kepada Qoinpay.
                </li>
              </ol>
              <h3>S. Lain-Lain</h3>
              <ol>
                <li className="my10px">
                  Ketentuan Layanan ini tunduk dan dilaksanakan sesuai dengan
                  hukum Republik Indonesia
                </li>
                <li className="my10px">
                  Apabila terjadi perselisihan dalam penafsiran atau pelaksanaan
                  dari Ketentuan Layanan maka Qoinpay dan Pengguna setuju untuk
                  menyelesaikan perselisihan tersebut melalui jalan musyawarah.
                  Dalam hal musyawarah mufakat tidak tercapai dalam jangka waktu
                  30 (tiga puluh) hari kalender, maka selanjutnya perselisihan
                  akan diselesaikan melalui Pengadilan Negeri Jakarta Selatan
                </li>
                <li className="my10px">
                  Ketentuan Layanan ini dibuat dalam Bahasa Indonesia dan Bahasa
                  Inggris. Dalam hal terdapat ketidaksesuaian diantara keduanya,
                  maka Qoinpay dan Pengguna setuju untuk menggunakan Bahasa
                  Indonesia sebagai bahasa yang mengikat
                </li>
                <li className="my10px">
                  Qoinpay termasuk nama dan logo, kode, desain, teknologi dan
                  formula bisnisnya, dilindungi oleh suatu hak cipta, merek,
                  paten dan hak atas kekayaan intelektual lainnya serta tunduk
                  pada hukum dan peraturan perundang-undangan yang berlaku di
                  Republik Indonesia. Qoinpay (dan pihak-pihak yang terkait
                  dengan perizinan Qoinpay, jika ada) memiliki semua hak dan
                  kepentingan Qoinpay terkait hak atas kekayaan intelektual yang
                  mengikutinya. Ketentuan Layanan ini tidak mengakibatkan
                  beralihnya atau memberikan hak bagi Pengguna untuk menggunakan
                  hak atas kekayaan intelektual Qoinpay dalam arti yang
                  seluas-luasnya terhadap hak-hak atas kekayaan intelektual
                  sebagaimana disebutkan di atas
                </li>
                <li className="my10px">
                  Kegagalan atau tertundanya Qoinpay dalam mengambil tindakan
                  langsung, mengenakan sanksi, atau melaksanakan hak atau
                  kewajiban dan/atau mengajukan gugatan atau tuntutan terhadap
                  pelanggaran Ketentuan Layanan ini, tidak menghapuskan
                  kewajiban Pengguna dan bukan merupakan suatu pengesampingan
                  atas hak Qoinpay untuk mengambil tindakan apapun yang
                  diperlukan di kemudian hari
                </li>
                <li className="my10px">
                  Pengguna dilarang mengalihkan hak dan kewajibannya dalam
                  Ketentuan Layanan tanpa persetujuan terlebih dahulu dari
                  Qoinpay berdasarkan pertimbangan dan kebijakan Qoinpay semata
                </li>
                <li className="my10px">
                  Jika terdapat suatu ketentuan dalam Ketentuan Layanan ini yang
                  ternyata diketahui melanggar ketentuan perundang-undangan yang
                  berlaku baik sebagian maupun seluruhnya, maka ketentuan yang
                  dianggap melanggar tersebut dikesampingkan dari Ketentuan
                  Layanan ini dan atas Ketentuan Layanan lain tetap berlaku dan
                  mengikat
                </li>
                <li className="my10px">
                  Ketentuan Layanan ini baik sebagian maupun seluruhnya termasuk
                  fitur atau layanan yang ditawarkan oleh Qoinpay dapat diubah,
                  diperbaharui, dan/atau ditambah dari waktu ke waktu dengan
                  berdasarkan kepada kebijakan yang berlaku. Berdasarkan
                  pembaharuan dan/atau penambahan tersebut Pengguna dengan ini
                  menyatakan menerima perubahan, pembaharuan dan/atau penambahan
                  tersebut
                </li>
                <li className="my10px">
                  Pengguna dengan ini mengetahui dan memberikan persetujuan
                  kepada Qoinpay untuk menggunakan data pribadi/informasi
                  Pengguna pada fitur-fitur yang tersedia pada aplikasi (atau
                  yang akan dikembangkan di kemudian hari) untuk pengembangan
                  jenis/model layanan dan penggunaan aplikasi Qoinpay, dengan
                  tetap memperhatikan ketentuan Kebijakan Privasi serta
                  ketentuan perundang-undangan yang berlaku
                </li>
                <li className="my10px">
                  Pengelolaan data pribadi Pengguna diatur dalam Kebijakan
                  Privasi (Privacy Policy) dimana Kebijakan Privasi tersebut
                  merupakan satu kesatuan dan bagian yang tidak terpisahkan
                  Ketentuan Layanan dan karenanya dengan ini menyatakan bahwa
                  Pengguna menerima dan menyetujui Kebijakan Privasi (Privacy
                  Policy) yang terkait
                </li>
                <li className="my10px">
                  Transaksi Pengguna yang dilakukan melalui Qoinpay disimpan
                  secara otomatis pada server Qoinpay. Pengguna mengakui dan
                  memahami bahwa Qoinpay telah berupaya sebaik-baiknya untuk
                  menjaga keamanan sistem. Namun demikian, dalam hal terdapat
                  perbedaan atau ketidaksesuaian antara data, catatan transaksi
                  Pengguna maka Pengguna menyetujui bahwa data atau catatan
                  transaksi yang berlaku dan diakui adalah yang dimiliki oleh
                  Qoinpay
                </li>
                <li className="my10px">
                  Pengguna memahami bahwa dalam menggunakan Qoinpay, data
                  pribadi dari Pengguna akan dikumpulkan, digunakan maupun
                  diperlihatkan sehingga Pengguna dapat menikmati layanan
                  Qoinpay secara maksimal, karenanya Pengguna dengan ini
                  menyetujui Qoinpay untuk menggunakan data pribadi tersebut
                  kepada pihak ketiga yang bekerja sama dengan Qoinpay
                </li>
                <li className="my10px">
                  Pengguna dapat menghubungi Layanan Pengguna (Customer Service)
                  Qoinpay atau masuk ke dalam aplikasi dan situs web untuk
                  mengetahui informasi terkait dengan layanan Qoinpay;
                </li>
                <li className="my10px">
                  Pengguna akan menerima pemberitahuan mengenai layanan atau
                  promosi terbaru melalui media komunikasi pribadi atau melalui
                  aplikasi Qoinpay. Bila Pengguna keberatan untuk menerima
                  pemberitahuan tersebut maka Pengguna dapat menghubungi Layanan
                  Pengguna (Customer Service) Qoinpay.
                </li>
              </ol>
              <h3>T. Keamanan dan Kerahasiaan Transaksi</h3>
              <label>
                Pengguna mengerti dan menyetujui bahwa Ketentuan Layanan ini
                merupakan perjanjian dan tindakan yang diambil Pengguna saat
                menekan tombol daftar atau tombol masuk saat akan mengakses
                Qoinpay merupakan persetujuan untuk mengikatkan diri dalam
                perjanjian dengan Qoinpay sehingga keberlakuan Ketentuan Layanan
                ini adalah sah dan mengikat secara hukum dan terus berlaku
                sepanjang penggunaan aplikasi oleh Pengguna Qoinpay. Qoinpay
                dapat mengalihkan hak Qoinpay berdasarkan Ketentuan Layanan ini
                tanpa persetujuan terlebih dahulu dari Pengguna atau
                pemberitahuan sebelumnya kepada Pengguna.
              </label>
            </div>
          </div>
        </div>

        <div className="container-aggree">
          <button className="button-aggree" onClick={this.onClickAggree.bind(this)}>
            Setuju
          </button>
        </div>
        <div className="footer">
          <span>© Qoinpay 2022, All Rights Reserved.</span>
        </div>
      </div>
    );
  }
}

export default withRouter(Landing);