import React, { Component } from "react";
import Logo from './../assets/newlogo.png';

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="header-logo">
            <img src={Logo} className="logo" />
        </div>
        )
    }
}